@import "@/assets/styles/variable.scss";

.set-password-page {
  width: 860px;
  margin: 0 auto;
  padding-top: 100px;
  .reset-pwd-form {
    width: 558px;
    margin: 0 auto;
    .ant-form-item {
      height: 98px;
      margin-bottom: 0;
    }
    .common-form-item-title {
      margin-bottom: 8px;
    }
  }
  .fill-verification-code {
    
  }
  .new-password-box {
    width: 558px;
    margin: 0 auto;
    .form-item {
      height: 98px;
      &.form-item-submit {
        height: 56px;
        margin-top: 72px;
        margin-bottom: 120px;
        button {
          width: 100%;
          height: 56px;
        }
      }
    }
  }
  @media #{$mobile} {
    width: 100%;
    margin: 0;
    padding: pv(44px 24px);
    .reset-pwd-form,
    .new-password-box {
      width: 100%;
    }
  }
}
