@import "@/assets/styles/variable.scss";

.acu-services-page {
  width: 754px;
  margin: 0 auto;
  padding-top: 100px;
  .page-title {
    height: 40px;
    line-height: 40px;
    font-size: 32px;
    color: #46604E;
    font-weight: 700;
    margin-bottom: 8px;
  }
  .page-desc {
    height: 20px;
    line-height: 20px;
    font-size: 14px;
    color: #2F3236;
    margin-bottom: 40px;
  }

  .service-list {
    .form-item-title {
      height: 18px;
      line-height: 18px;
      margin-bottom: 8px;
      font-size: 12px;
      font-weight: 700;
      color: rgba(47, 50, 54, 0.5);
      text-transform: uppercase;
    }
    .form-row {
      display: flex;
      justify-content: space-between;
      .first-form-item {
        width: 354px;
      }
      .second-form-item {
        width: 158px;
      }
      .third-form-item {
        width: 158px;
      }
      .anticon-close-circle{
        position: relative;
        top: 36px;
      }
    }
    .add-btn-container {
      padding-bottom: 40px;
      .add-btn {
        height: 20px;
        line-height: 20px;
        font-size: 14px;
        font-weight: 500;
        color: #2F80ED;
        cursor: pointer;
      }
    }
  }
  @media #{$mobile} {
    width: 100%;
    padding: 0 !important;
    margin: 0;
    .page-title,.page-desc{
      height: auto;
    }
    .service-list{
      .form-row{
        flex-wrap: wrap;
        width: 100%;
        position: relative;
        .ant-form-item{
          width: 100% ;
        }
        span.anticon-close-circle{
          position: absolute !important;
          top: 0 !important;
          right: 0;
        }
        .form-column{
          &:first-child{
            width: 100%;
          }
          &:nth-child(2),&:nth-child(3){
            width: 48%;
            div{
              width: 100% !important;
            }
          }
        }
      }
    }
  }
}
