@import "./profile_doctor_card.scss";

.role-card {
  .clinic-scores {
    display: flex;
    justify-content: space-between;
    padding-top: 72px;
    .clinic-scores-item {
      width: 90px;
      text-align: left;
      &:last-child {
        width: 75px;
      }
      .clinic-scores-title {
        height: 18px;
        line-height: 18px;
        text-align: left;
        margin-bottom: 4px;
        font-size: 12px;
        font-weight: 700;
        color: #b5b5be;
        text-transform: uppercase;
      }
      .clinic-scores-score {
        & > span {
          color: #449086;
          font-family: Roboto;
          font-weight: 700;
          &:first-child {
            height: 40px;
            line-height: 40px;
            font-size: 40px;
          }
          &:last-child {
            font-size: 18px;
            letter-spacing: 0.8px;
          }
        }
      }
    }
  }
}
