@import '@/assets/styles/variable.scss';
.ftl-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  .fill-profile-page {
    flex: 1;
  }
  @media #{$mobile} {
    padding: pv(60px 24px 24px);
  }
}
