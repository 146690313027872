@import '../account/account_verify_form.scss';

.claim-email{
  .claim-title{
    font-size: 26px;
    font-weight: bold;
  }
  .ant-btn {
    height: 56px;
  }
  .tips {
    background-color: $profile-bg-color;
    color: red;
    padding: 6px;
  }
}