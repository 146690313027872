@import "@/assets/styles/variable.scss";

.not-found-page {
  height: 100%;
  padding-top: 132px;
  padding-bottom: 132px;
  background: linear-gradient(180deg, #46604E 0%, #3C5644 100%);
  .not-found-icon {
    text-align: center;
    margin-bottom: 40px;
    img {
      width: 386px;
      height: 168px;
    }
  }
  .sorry-message {
    margin-bottom: 36px;
    & > div {
      height: 28px;
      line-height: 28px;
      text-align: center;
      font-size: 18px;
      color: #FFFFFF;
    }
  }
  .handle-btns {
    text-align: center;
    & > button {
      &:first-child {
        margin-right: 24px;
      }
    }
  }
  .or {
    height: 18px;
    line-height: 18px;
    margin: 48px 0;
    font-size: 12px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    font-weight: 700;
    text-align: center;
    color: rgba(255, 255, 255, 0.5);
  }
  .search-container {
    .common-search {
      margin: 0 auto;
    }
  }

  @media #{$mobile} {
    padding: pv(80px 24px 88px);
    .not-found-icon {
      img {
        width: pv(282px);
        height: pv(124px);
      }
    }
    .sorry-message {
      & > div {
        height: pv(84px);
        line-height: pv(28px);
      }
    }
    .or {
      margin: pv(32px 0);
    }
  }
}
