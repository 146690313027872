@import "react-big-calendar/lib/sass/variables";
// modify .bg-calendar default vairables
$calendar-border: transparent;

@import "react-big-calendar/lib/sass/styles";
@import "@/assets/styles/variable";
.event-calendar {
  background-color: #fff;
  padding: 16px 32px;

  .head-bar {
    display: flex;
    justify-content: space-between;
    .section {
      &.legend{
        display: flex;
        justify-content: flex-start;
        &>div{
          flex: 0;
        }
      }
      .ant-badge {
        width: 9rem;
      }
      &.filter {
        display: flex;
        .ant-btn {
          height: 3rem;
          &.action {
            margin-left: 12px;
          }
        }
        .grp {
          display: flex;
          width: 14rem;
          border: 1px solid #d9d9d9;
          .ant-select{
          }
          .ant-btn.show{
            border: none;
          }
        }
      }
    }
  }
  .bg-calendar {
    width: 100%;
    height: 100%;
    .rbc-time-view {
      margin-top: 16px;
      .rbc-time-header {
        .rbc-time-header-content {
          .rbc-allday-cell {
            display: none;
          }
          .rbc-time-header-cell {
            height: 50px;
            .rbc-header {
              display: flex;
              justify-content: center;
              align-items: center;
              // &.rbc-today{
              //   background-color: #fff;
              //   button{
              //     height: 46px;
              //     width: 46px;
              //     border-radius: 50%;
              //     background: $theme-green;
              //   }
              // }
            }
          }
        }
      }
      .rbc-time-header > .rbc-time-header-gutter,
      .rbc-time-content > .rbc-time-gutter {
        flex: none;
        width: 68px !important;
        min-width: 68px !important;
        max-width: 68px !important;
        .rbc-timeslot-group {
          // border-top: 1px solid $cell-border;
          border: none;
        }
      }
      .rbc-time-content {
        .rbc-day-slot.rbc-time-column {
          &.rbc-today {
            background: transparent;
          }
          .rbc-events-container {
            .rbc-event {
              background-color: #e9f5f8;
              border: none;
              border-radius: 0;
              border-left: 3px solid #5f9595;
              color: #000;
              display: block;
              & > div {
                @include text-ellipsis();
              }

              &.BOOKING_REVIEW {
                background-color: #f8e2d3;
                border-left-color: #e69f6c;
              }
              &.BOOKING_FAILED {
                background-color: #f3bbbb;
                border-left-color: #eb0a0a;
              }
              &.CANCEL_SUCCESS {
                background-color: #7891a8;
                border-left-color: #02375f;
                color: #fff;
              }
              //schedule item
              &.once{
                background-color: #c3a3fd;
                border-left-color: #9c66ff;
              }
              &.weekly{
                background-color: #ae9cff;
                border-left-color: #7151ff;
              }
            }
          }
        }
      }
    }
  }

  @media #{$mobile} {
    padding: pv(16px);
    .rbc-toolbar {
      .rbc-btn-group {
        width: 100%;
        text-align: center;
      }
    }
  }
}
