@import '@/assets/styles/variable.scss';
.profile-book-service{
    width: 100%;
    padding: 12px;
    .book-title{
        font-size: 26px;
        color: $font-green;
        font-weight: bold;
        text-align: center;
        margin-bottom: 12px;
    }
    .service-grp,.empty-tip{
        background: #fff;
        margin-bottom: 12px;
        min-height: 56px;
        padding: 12px;
        font-size: 18px;
        width: 100%;
        &.empty-tip{
            display: flex;
            align-items: center;
            justify-content: center;
            color: #cabebe;
        }
        .ant-space.ant-space-vertical{
            width: 100%;
            .ant-space-item{
                width: 100%;
                .ant-radio-wrapper{
                    width: 100%;
                    border: 2px solid #eee;
                    padding: 8px 6px;
                    border-radius: 4px;
                    &.ant-radio-wrapper-checked{
                        border-bottom-color: $theme-green;
                    }
                }
            }
        }
    }
    .ant-btn{
        width: 100%;
        height: 46px;
    }
}