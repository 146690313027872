@import "@/web/comps/booking_summary.scss";

.acu-appointment-detail{
  width: 80%;
  background: #fff;
  padding: 32px 32px 100px;
  .status{
    font-size: 18px;
    font-weight: bold;
    font-family: $body-font-merriweather;
    padding: 12px;
    background: #d4fcd7;
    color: #019443;
    border-radius: 4px;
    margin-bottom: 16px;

    &.BOOKING_REVIEW{
      background-color: #f8e2d3;
      color: #E69F6C;
    }
    &.BOOKING_FAILED{
      background-color: #f3bbbb;
      color: #eb0a0a;
    }
    &.CANCEL_SUCCESS{
      background-color: #eee;
      color: #888;
    }
  }
  
  @media #{$mobile} {
    width: 100%;
  }
}
