@import "@/assets/styles/variable.scss";

.doctor-patient-reviews {
  padding: 24px;

  .score-container {
    overflow: hidden;
    margin-bottom: 70px;
    position: relative;

    .score-container-left {
      width: 172px;
    }
    .score-container-right {
      width: 346px;
      float: left;
    }
    .score-container-circle {
      width: 132px;
      float: right;
    }
    @media #{$mobile} {
      padding: pv(32px 0 0);
      .score-container-left {
        margin-bottom: pv(26px);
        padding-left: pv(160px);
      }
      .score-container-circle {
        position: absolute;
        top: pv(24px);
        left: pv(16px);
      }
    }
  }
  .reviews-filter {
    margin-bottom: 24px;
    .filter {
      width: 384px;
      display: flex;
      justify-content: space-between;
      .filter-show {
        width: 188px;
        .ant-select.filter-show-select {
          .ant-select-selector {
            position: relative;
            padding-left: 58px;
            &::before {
              content: "Show:";
              height: 46px;
              line-height: 46px;
              color: $font-color-light;
              font-size: 14px;
              position: absolute;
              top: 0;
              left: 12px;
            }
          }
        }
      }
      .filter-latest {
        width: 188px;
        .ant-select.filter-latest-select {
          .ant-select-selector {
            position: relative;
            padding-left: 68px;
            &::before {
              content: "Sort by:";
              height: 46px;
              line-height: 46px;
              color: $font-color-light;
              font-size: 14px;
              position: absolute;
              top: 0;
              left: 12px;
            }
          }
        }
      }
    }
  }
}
