@import "@/assets/styles/variable.scss";

.ant-btn.google-login-btn {
  background-color: #4d74f3;
  color: #fff;
  height: 56px;
  width: 100%;
  display: flex;
  align-items: center;
  &>span{
    flex: 1;
    margin-right: 32px;
  }
  &:hover{
    border-color: #4d74f3;
    background: #2e5bee;
    color: #dad8d8;
  }
}