@import '@/assets/styles/variable.scss';

.account-setting{
  .info{
    width: 80%;
    background: #fff;
    padding: 60px 40px 60px;
    .info-title{
      font-size: 18px;
      font-weight: bold;
    }
    .upload-avatar-wrapper{
      text-align: left;
      margin: 36px 0;
    }
    .flex{
      display: flex;
      justify-content: space-between;
      &>*{
        width: 48%;
      }
    }
  }
  
  .submit-btn{
    margin: 16px 0;
    .ant-btn{
      height: 56px;
      width: 200px;
    }
  }
  @media #{$mobile} {
    .info{
      width: 100%;
      .info-title{
        display: none;
      }
      .upload-avatar-wrapper{
        text-align: center;
        margin: pv(36px 0);
      }
      .flex{
        display: block;
        &>*{
          width: 100%;
        }
      }
    }
    
    .submit-btn{
      text-align: center;
      .ant-btn{
        width: 80%;
        height: pv(56px);
      }
    }
  }
}