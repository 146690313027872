@import "@/assets/styles/variable.scss";

.role-card {
  width: 1146px;
  min-height: 222px;
  padding: 24px;
  background: #FFFFFF;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 128px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  @media #{$mobile} {
    display: block;
    height: unset;
    position: relative;
    left: 0;
    transform: translate(0);
    top: pv(-110px);
    width: calc(100vw - pv(48px));
    margin: pv(0 24px -100px);
  }
  justify-content: space-between;
  &.role-card__doctor {
    height: 254px;
    @media #{$mobile} {
      height: unset;
    }
  }
  .role-avatar {
    width: 200px;
    height: 200px;
    padding: 4px;
    .ant-avatar{
      &, img, [role=img] {
        width: 100%;
        height: 100%;
        &.anticon-user{
          display: flex;
          justify-content: center;
          align-items: center;
          svg{
            width: 60%;
            height: 60%;
          }
        }
      }
    }
    .claim{
      text-decoration: underline;
      font-style: italic;
      margin-top: 4px;
    }
    @media #{$mobile} {
        width: 100%;
        height: 100%;
      .ant-avatar,.ant-avatar>img {
        width: 100%;
        height: 100%;
      }
    }

  }

  .role-infos {
    width: 478px;
    height: 100%;
    .infos-title {
      height: 32px;
      line-height: 32px;
      margin-top: 8px;
      margin-bottom: 4px;
      font-size: 24px;
      font-weight: 700;
      color: #2C2F46;
      font-family: $body-font-merriweather;
    }
    .infos-desc {
      height: 18px;
      line-height: 18px;
      margin-bottom: 24px;
      font-size: 12px;
      color: $font-color-light;
      letter-spacing: 0.5px;
      font-weight: 700;
    }
    @media #{$mobile} {
      width: 100%;
      .infos-title,
      .infos-desc{
        text-align: center;
      }
    }
    .infos-list {
      .infos-item {
        display: flex;
        margin-top: 8px;
        min-height: 24px;
        .infos-item-icon {
          width: 24px;
          height: 24px;
          margin-right: 12px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .infos-item-value {
          height: 24px;
          line-height: 24px;
          font-size: 14px;
          color: $font-color;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          a {
            color: $link-blue;
            font-size: 14px;
            text-decoration: underline;
          }
          .links {
            cursor: pointer;
            color: $link-blue;
          }
        }
      }
    }
  }

  .role-book {
    width: 350px;
    height: 100%;
    text-align: right;
    .btns {
      display: flex;
      justify-content: flex-end;
    }
    .role-scores {
      display: flex;
      justify-content: flex-end;
      margin-top: 78px;
      .scores {
        height: 56px;
        line-height: 56px;
        margin-right: 16px;
        font-family: $body-font-merriweather;
        font-size: 48px;
        font-weight: 700;
        color: #449086;
      }
      .stars-reviews {
        .stars {
          margin-bottom: 8px;
          img {
            width: 16px;
            height: 16px;
            margin-right: 8px;
          }
        }
        .reviews {
          height: 20px;
          line-height: 20px;
          font-size: 14px;
          color: #696974;
          text-align: left;
        }
      }
    }
    &.role-book-doctor {
      .btns {
        display: inline-block;
        button{
          min-width: 175px;
          height: 38px;
          display: block;
          &:first-child{
            margin-bottom: 12px;
          }
        }
        justify-content: inherit;
      }
      .role-scores {
        margin-top: 62px;
      }
    }

    @media #{$mobile} {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column-reverse;
      .btns {
        justify-content: center;
        align-items: center;
        flex-direction: column-reverse;
        &>*{
          display: block;
          margin-top: pv(16px);
        }
      }
    }
  }
}

.claim-overlay{
  .ant-tooltip-content {
    .ant-tooltip-inner{
      color: #2C2F46;

      .ant-btn{
        margin-top: 6px;
      }
    }
  }
}