@import "./finished.scss";

.finished-summary.failed {
  .info > .right {
    color: red;
    display: flex;
    align-items: center;
  }
  .why {
    width: 100%;
    margin: 12px 0;
    color: $link-blue;
    cursor: pointer;
  }
}
