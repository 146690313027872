@import "@/assets/styles/variable.scss";

.search-result-in-google-map {
  width: 100%;
  height: 800px;
  background: lightgray;
  position: relative;
  overflow: hidden;
  .card-list-container {
    width: 418px;
    height: 800px;
    background: #FBF8F5;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 999;
    &.card-list-container__show {
      padding: 16px 0 16px 16px;
      border-left: 3px solid #E7E7ED;
      transition: right 1s;
    }
    &.card-list-container__hidden {
      right: -420px;
    }
    .toggle-btn {
      width: 36px;
      height: 60px;
      background: #FBF8F5;
      cursor: pointer;
      position: absolute;
      left: -36px;
      top: 50%;
      transform: translateY(-50%);
      z-index: 99;
      .ant-btn{
        height: 100%;
      }
    }
    .filter-select {
      position: relative;
      padding-right: 16px;
      margin-bottom: 16px;
      .ant-select {
        .ant-select-selector {
          padding-left: 64px;
          .ant-select-selection-item {
            font-weight: 700;
          }
        }
      }
      .filter-tip {
        display: block;
        height: 20px;
        line-height: 20px;
        font-size: 14px;
        color: $font-color-light;
        position: absolute;
        top: 14px;
        left: 12px;
      }
    }
    .card-list {
      height: 720px;
      padding-right: 16px;
      overflow-y: auto;
      .search-result-card{
        width: 376px;
        margin-bottom: 6px;
        .card-left{
          .ant-avatar {
            &, img, [role=img] {
              width: 100%;
              height: 100%;
              min-width: 72px;
              display: flex;
              justify-content: center;
              align-items: center;
              svg{
                width: 60%;
                height: 60%;
              }
            }
          }
        }
        .score-infos{
          display: block;
        }
        .card-infos{
          .card-infos-item{
            .value{
              width: 206px;
            }
          }
        }
      }
    }
  }
  .google-map-container {
    width: 100%;
    height: 100%;
    .google-map {
      width: 100%;
      height: 100%;
      .gm-style-iw.gm-style-iw-c {
        width: 386px;
        height: 116px;
        padding: 0;
        border-radius: 0;
        .gm-ui-hover-effect {
          display: none !important;
        }
        .gm-style-iw-d {
          width: 100%;
          height: 100%;
          padding: 0;
          overflow: unset !important;
          max-height: unset !important;
          & > div {
            width: 100%;
            height: 100%;
          }
          .info-window-container {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: space-between;
            .avatar {
              width: 116px;
              height: 116px;
              &.has-avatar {
                img {
                  width: 100%;
                  height: 100%;
                }
                div {
                  display: none;
                }
              }
              &.no-avatar {
                img {
                  display: none;
                }
                div {
                  width: 100%;
                  height: 100%;
                  background: url('../../assets/images//no-avatar.png') no-repeat;
                  background-size: 100% 100%;
                }
              }
            }
            .infos {
              width: 256px;
              padding: 14px 10px 14px 0;
              .name {
                width: 100%;
                height: 18px;
                line-height: 18px;
                font-size: 14px;
                font-weight: 700;
                color: #28292A;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
              .medical-title {
                height: 22px;
                line-height: 22px;
                font-size: 14px;
                color: #71787E;
              }
              .reviews {
                display: flex;
                & > span {
                  height: 20px;
                  line-height: 20px;
                  font-size: 12px;
                }
                .review-count {
                  font-weight: 500;
                  color: $font-color-light;
                }
                .cured {
                  display: flex;
                  padding-left: 8px;
                  &.cured_c {
                    display: none;
                  }
                  span {
                    display: block;
                    height: 20px;
                    line-height: 20px;
                    font-weight: 500;
                    &.icon_cured_high, &.icon_cured_low {
                      width: 20px;
                      height: 20px;
                      background-position: 0 2px !important;
                    }
                    &.icon_cured_high {
                      background: url('../../assets/images/icon-card-cure-slow.svg') no-repeat;
                    }
                    &.icon_cured_low {
                      background: url('../../assets/images/icon-card-cure-high.svg') no-repeat;
                    }
                    &.green {
                      color: #449086;
                    }
                    &.red {
                      color: red;;
                    }
                  }
                }
              }
              .address {
                display: flex;
                justify-content: space-between;
                span.icon.icon-location {
                  display: block;
                  width: 14px;
                  height: 20px;
                  background: url('../../assets//images/icon-card-location.svg') no-repeat;
                  background-position: -5px -2px;
                }
                span {
                  display: block;
                  width: 218px;
                  height: 20px;
                  line-height: 20px;
                  font-size: 12px;
                  color: $font-color-light;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                }
              }
            }
          }
        }
      }
    }
  }

  @media #{$mobile} {
    width: 100%;
    height: 100vw;
    .card-list-container{
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 2;
      &.card-list-container__show{
        border: none;
        padding: 0;
      }
      &.card-list-container__hidden{
        width: 100%;
        right: -100vw;
      }
      .toggle-btn{
        width: 100%;
        position: static;
        transform: unset;
      }
      .filter-select{
        padding: 0;
      }
      .card-list{
        padding: pv(0 0 16px);
        height: auto;
        .card-list-item{
          .card-item-infos{
            width: 65%;
            padding: pv(12px);
            .name,.title{
              height: auto;
              line-height: 1.2;
            }
          }
        }
      }
    }
    .google-map-container{
      width: 100%;
      height: 100vw;
    }
    .toggle-mode-container{
      width: pv(160px);
      height: pv(40px);
      padding: pv(6px);
      top: unset;
      right: unset;
      bottom: pv(6px);
      left: pv(6px);
    }
  }
}
