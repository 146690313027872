@import "@/assets/styles/variable.scss";

.book-card {
  display: flex;
  justify-content: space-between;
  height: 128px;
  padding: 16px;
  margin-bottom: 16px;
  background: #fbfbfb;
  position: relative;
  width: 100%;
  .book-card-left {
    width: 88px;
  }
  .book-card-middle {
    width: 50%;
    margin: 0 auto 0 12px;
    .doctor-name{
      height: 28px;
      line-height: 28px;
      font-size: 18px;
      font-weight: 500;
      color: #2c2f46;

    }
    .medical-title{
      font-size: 12px;
      color: rgba(44, 47, 70, 0.5);
      font-weight: 700;
    }
    .reviews-sec{
      .reviews {
        font-size: 14px;
        color: #696974;
      }
    }
    .description {
      width: 100%;
      height: 20px;
      line-height: 20px;
      font-size: 14px;
      color: #696974;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .book-card-right {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    padding: 6px;
    width: 180px;

    .ant-checkbox {
      width: 20px;
      height: 20px;
      top: 6px;
      right: 6px;
      .ant-checkbox-inner {
        width: 100%;
        height: 100%;
        &::after {
          width: 8px;
          height: 8px;
          transform: rotate(315deg) scale(-1, 1) translate(-30%, -60%);
        }
      }
      &.ant-checkbox-checked {
        .ant-checkbox-inner::after {
          width: 14px;
          height: 10px;
          transform: rotate(315deg) scale(-1, 1) translate(-30%, -60%);
        }
      }
    }
    .btn-view-detail{
      // position: absolute;
      // top: 6px;
      // right: 90px;
    }
  }
  @media #{$mobile} {
    position: relative;
    padding-bottom: pv(190px);
    .book-card-left {
      width: pv(88px);
      & > span {
        width: pv(88px);
        height: pv(88px);
      }
    }
    .book-card-middle {
      padding-left: pv(16px);
      width: calc(100% - pv(88px));
      .doctor-name {
        width: 100%;
        word-wrap: break-word;
        height: auto !important;
      }
    }
    .book-card-right {
      position: absolute;
      height: pv(36px);
      left: pv(100px);
      width: pv(138px);
      bottom: pv(16px);
      .ant-checkbox{
        display: none;
      }
    }
  }
}

.score-card {
  width: 216px;
  height: 92px;
  padding: 16px 24px;
  background: #f5f8fc;

  .title {
    height: 18px;
    line-height: 18px;
    margin-bottom: 4px;
    font-size: 12px;
    color: #b5b5be;
    font-weight: 700;
    letter-spacing: 0.5px;
    text-transform: uppercase;
  }
  .score {
    height: 40px;
    line-height: 40px;
    font-size: 32px;
    color: #449086;
    font-weight: 700;
  }
}

.score-card-list {
  @media #{$mobile} {
    width: 100%;
    flex-wrap: wrap;
    .score-card {
      flex: 1;
    }
  }
}

.review-card {
  min-height: 258px;
  padding: 16px;
  background: #fbfbfb;
  & + .review-card {
    margin-top: 16px;
  }
  &:last-child {
    margin-bottom: 10px;
  }
  .review-info {
    overflow: hidden;
    padding-bottom: 16px;
    .avatar {
      float: left;
      margin-right: 10px;
    }
    .name-date {
      float: left;
      & > div {
        min-height: 20px;
        line-height: 1.2;
        font-size: 14px;
        color: $font-color;
        &.name {
          font-weight: 700;
        }
        &.date {
        }
      }
    }
    .review-stars {
      //width: 140px;
      float: right;
    }
  }
  .review-desc {
    min-height: 92px;
    line-height: 20px;
    padding: 16px 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    font-size: 14px;
    color: $font-color;
    a {
      padding-left: 6px;
    }
  }
  .review-score {
    display: flex;
    padding-top: 16px;
    .score-card {
      width: unset;
      height: unset;
      padding: 0;
      margin-right: 40px;
      background: transparent;
    }
  }

  @media #{$mobile} {
    height: auto;
    .review-desc {
      height: auto;
    }
    .review-score {
      justify-content: space-between;
      .score-card {
        margin: 0;
      }
    }
  }
}

.doctor-review-card {
  padding: 16px;
  background: #fbfbfb;
  margin-bottom: 16px;
  .doctor-card-info {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding-bottom: 16px;
    .doctor-card-info-left {
      width: calc(100% - 70px);
      .info-item {
        display: flex;
        margin-bottom: 3px;
        & > div {
          height: 20px;
          line-height: 20px;
          font-size: 14px;
          color: $font-color;
        }
        .info-item-label {
          width: 95px;
          font-weight: 500;
        }
      }
    }
    .doctor-card-info-right {
      width: 70px;
      display: flex;
      justify-content: space-between;
      img {
        width: 20px;
        height: 20px;
      }
      span {
        height: 20px;
        line-height: 20px;
        margin-left: 10px;
        font-size: 14px;
        color: #696974;
      }
    }
    @media #{$mobile} {
      flex-direction: column-reverse;
      .doctor-card-info-right {
        margin-bottom: pv(12px);
      }
      .doctor-card-info-left {
        width: 100%;
        .info-item {
          .info-item-label {
            font-weight: bold;
            flex: 1;
          }
          .info-item-value {
            flex: 1.5;
          }
          div {
            height: auto;
          }
        }
      }
    }
  }
  .doctor-card-desc {
    margin-top: 16px;
    .score {
      display: flex;
      margin-bottom: 18px;
      .name {
        height: 22px;
        line-height: 22px;
        padding-left: 10px;
        font-size: 14px;
        color: $font-color;
        font-weight: 500;
      }
    }
    .description {
      line-height: 20px;
      font-size: 14px;
      color: $font-color;
      a {
        height: 20px;
        line-height: 20px;
        padding-left: 6px;
        font-size: 14px;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
