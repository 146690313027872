@import "@/assets/styles/variable.scss";

.account-user-cards {
  position: relative;
  width: 80%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .card{
    width: 48%;
    padding: 16px;
    border: 2px solid #eee;
    border-radius: 6px;
    background: #fff;
    .line{
      display: flex;
      font-size: 16px;
      margin-bottom: 6px;
      .key{
        font-weight: bold;
        padding-right: 6px;
      }
      &.card-title{
        justify-content: space-between;
        margin-bottom: 16px;
        .key{
          color: #aaa;
        }
      }
      &.action {
        justify-content: space-between;
      }
    }
  }

  .no-data {
    text-align: center;
    .no-data-img {
      img {
        width: 328px;
        height: 240px;
      }
    }
    .no-data-tip {
      margin-top: 40px;
      height: 32px;
      line-height: 32px;
      font-size: 24px;
      font-weight: 700;
      font-family: $body-font-merriweather;
      color: #aaa;
    }
   
  }

  @media #{$mobile} {
    width: 100%;
    
  }
}
