@import "@/assets/styles/variable.scss";

.review-doctor-modal {
  .ant-modal-body {
    padding: 36px;
    .modal-header {
      position: relative;
      box-shadow: inset 0px -1px 0px #efeff1;
      padding-bottom: 24px;
      &.modal-header__clinic {
        height: 96px;
      }
      .title-1 {
        height: 24px;
        line-height: 24px;
        font-size: 16px;
        font-weight: 700;
        letter-spacing: 0.5px;
        color: $font-color-light;
        text-transform: uppercase;
        margin-bottom: 12px;
      }
      .title {
        height: 32px;
        line-height: 32px;
        font-size: 24px;
        font-weight: 700;
        color: $font-color;
      }
      .desc {
        height: 20px;
        line-height: 20px;
        font-size: 14px;
        color: $font-color-light;
        b {
          color: $font-color;
        }
      }
    }
    .anticon.anticon-close {
      font-size: 20px;
      position: absolute;
      top: 38px;
      right: 40px;
      cursor: pointer;
    }
    .review-doctor-form {
      margin-top: 40px;
      .review-form-item {
        position: relative;
        .title {
          display: flex;
          .label {
          }
          .tip {
            height: 18px;
            line-height: 18px;
            font-size: 14px;
            color: $font-color-light;
            letter-spacing: 0.5px;
            padding-left: 10px;
          }
        }
        .label {
          //height: 18px;
          line-height: 18px;
          font-size: 12px;
          color: #36352b;
          letter-spacing: 0.5px;
          font-weight: 700;
          text-transform: uppercase;
          margin-bottom: 8px;
        }
        .unit-of-time {
          height: 20px;
          line-height: 20px;
          font-size: 14px;
          color: $font-color-light;
          position: absolute;
          bottom: -2px;
          right: 240px;
        }
        .ant-row.ant-form-item {
          margin-bottom: 32px;
          &.rate {
            line-height: 1;
            &.time-to-recovery {
              .ant-rate {
                color: #449086;
              }
            }
          }
          input.ant-input {
            height: 42px !important;
            padding: 10px 12px !important;
            line-height: 20px !important;
            font-size: 14px !important;
          }
          .ant-slider {
            width: 430px;
            .ant-slider-rail {
              height: 10px;
              background: #c1c9d6;
              border-radius: 24px;
            }
            .ant-slider-track {
              height: 10px;
              background: #449086;
              border-radius: 24px;
            }
            .ant-slider-step {
              position: relative;
              top: 3px;
              .ant-slider-dot {
                display: none;
                &.ant-slider-dot-active {
                  &:last-child {
                    //display: inline-block;
                    border: 4px solid #449086;
                    box-shadow: 0px 24px 30px rgba(82, 92, 229, 0.185424);
                  }
                }
              }
            }
            .ant-slider-handle {
              width: 16px;
              height: 16px;
              margin-top: -7px;
              border: 4px solid #449086;
              box-shadow: 0px 24px 30px rgba(82, 92, 229, 0.185424);
            }
            .ant-slider-mark {
              margin-top: 8px;
              .ant-slider-mark-text {
                height: 20px;
                line-height: 20px;
                font-size: 14px;
                color: $font-color;
                font-weight: 500;
              }
            }
          }
        }
      }
    }
    .review-doctor-added {
      padding-bottom: 64px;
      .block {
        width: 45px;
        height: 5px;
        background: #e69f6c;
        margin: 24px auto 32px;
      }
      .message-1 {
        text-align: center;
        height: 56px;
        line-height: 56px;
        margin-bottom: 32px;
        font-size: 48px;
        font-weight: 700;
        color: #46604e;
      }
      .message-2 {
        text-align: center;
        height: 20px;
        line-height: 20px;
        margin-bottom: 48px;
        font-size: 14px;
        color: $font-color;
      }
      .buttons {
        text-align: center;
        button {
          width: 244px;
          height: 56px;
          font-size: 16px;
          &:last-child {
            margin-left: 16px;
          }
        }
      }
    }
    .ant-radio-wrapper.ant-radio-wrapper-checked {
      .ant-radio {
        &.ant-radio-checked {
          .ant-radio-inner {
            border-color: #39b38e;
            &::after {
              background-color: #39b38e;
            }
          }
        }
      }
    }
    .ant-radio-wrapper:hover .ant-radio,
    .ant-radio:hover .ant-radio-inner,
    .ant-radio-input:focus + .ant-radio-inner {
      border-color: #39b38e;
    }
  }
  .ant-modal-footer {
    height: 96px;
    padding: 20px 16px;
    box-shadow: inset 0px 1px 0px #efeff1;
    text-align: right;
    button {
      width: 144px;
      height: 56px;
      margin-left: 16px !important;
    }
  }
  @media #{$mobile} {
    .ant-slider.ant-slider-with-marks {
      width: pv(280px) !important;
    }
    .unit-of-time {
      right: pv(-20px) !important;
      bottom: pv(-20px) !important;
    }
  }
}
