@import '@/assets/styles/variable.scss';

.signup-page {
  padding: 80px 0 80px;

  .signup-main {
    width : 558px;
    margin: 0 auto;

    .title-block {
      width     : 101px;
      height    : 7px;
      background: #E69F6C;
      margin    : 0 auto 40px;
    }

    .section-title {
      height       : 56px;
      line-height  : 56px;
      margin-bottom: 64px;
      text-align   : center;
      font-size    : 48px;
      font-weight  : 700;
      color        : $font-green;
    }

    .signup-form {
      position: relative;

      .toggle-password {
        width   : 16px;
        height  : 12px;
        position: absolute;
        top     : 142px;
        right   : 16px;
        cursor  : pointer;
      }

      .signup-form-item {
        height       : 72px;
        margin-bottom: 0;
      }

      .signup-form-links {
        margin   : 24px 0 36px;
        color    : #696974;
        font-size: 14px;
        position : relative;
        .txt {
          &::before {
            content     : '*';
            color       : red;
            position    : relative;
            margin-right: 6px;
          }
        }

        a {
          font-weight    : 500;
          padding        : 0 4px;
          color          : #696974;
          text-decoration: underline;
        }
      }

      .signup-form-btns {
        button {
          height        : 56px;
          text-transform: uppercase;
          font-size     : 16px;
          letter-spacing: 0.5px;
          position      : relative;

          &.email {
            border-color: #D9D9D9;

            &:hover {
              border-color: #D9D9D9;
            }
          }

          &.facebook {
            color        : #FFFFFF;
            margin-bottom: 12px;
            background   : #23599C;
            border-color : #23599C;

            &:hover {
              border-color: #23599C;
            }
          }

          &.google {
            color       : #FFFFFF;
            background  : #2F80ED;
            border-color: #2F80ED;

            &:hover {
              border-color: #2F80ED;
            }
          }

          img {
            position: absolute;
            top     : 12px;
            left    : 32px;
          }
        }

        .or {
          height        : 18px;
          line-height   : 18px;
          margin        : 27px 0;
          letter-spacing: 0.5px;
          font-size     : 12px;
          color         : rgba(47, 50, 54, 0.5);
          text-transform: uppercase;
          text-align    : center;
        }
      }

      .go-login {
        height     : 28px;
        line-height: 28px;
        margin-top : 40px;
        text-align : center;
        font-size  : 18px;
        color      : #2F3236;

        a {
          font-weight : 700;
          padding-left: 8px;
        }
      }
    }
  }

  @media #{$mobile} {
    width  : 100%;
    margin : 0;
    padding: pv(44px 24px) !important;

    .signup-main {
      width: 100%;
    }
  }
}