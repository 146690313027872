@import "@/assets/styles/variable.scss";

.app-footer {
  height: $footer-height;
  background: #36352b;
  box-shadow: inset 0px 0.5px 0px rgba(255, 255, 255, 0.5);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 48px 48px 0;
  &.white-bg-footer {
    background: #FBF8F5;
    .logo {
      & > div {
        color: $font-color-light;
      }
    }
    .links {
       a {
         color: $font-color-light;
       }
    }
  }
  & > div {
    height: 100%;
  }
  .logo {
    img {
      height: 36px;
    }
    div {
      height: 20px;
      line-height: 20px;
      font-size: 14px;
      color: rgba(255, 255, 255, 0.5);
    }
  }
  .links {
    padding-top: 8px;
    a {
      display: inline-block;
      height: 24px;
      line-height: 24px;
      margin-right: 24px;
      font-size: 16px;
      letter-spacing: 0.5px;
      color: rgba(255, 255, 255, 0.5);
      &:last-child {
        margin-right: 0;
      }
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .contact-us{
    text-decoration: none !important;
  }
  .responsive-copyright {
    display: none;
  }
  .social-medias {
    display: flex;
    padding: 50px 0;
    margin-right: -24px;
    span {
      display: block;
      width: 18px;
      height: 18px;
      margin-right: 24px;
      background-attachment: fixed;
      background: url("../../assets/images/social-medias.svg") no-repeat;
      cursor: pointer;
      &.facebook {
        background-position: 0 0;
      }
      &.google {
        background-position: -42px 0;
      }
      &.twitter {
        background-position: -84px 0;
      }
      &.instagram {
        background-position: -126px 0;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .app-footer {
    width: 100%;
    height: auto;
    padding: 32px 24px 48px;
    display: block;
    & > div {
      height: auto;
    }
    .logo {
      padding: 0;
      margin-bottom: 32px;
      text-align: center;
      img{
        width: pv(204px);
      }
      .pc-copyright {
        display: none;
      }
    }
    .links {
      padding: 0;
      margin-bottom: 32px;
      a {
        display: block;
        text-align: center;
        margin-right: 0;
        margin-top: 24px;
      }
    }
    .responsive-copyright {
      display: block;
      text-align: center;
      margin-bottom: 32px;
      // color: rgba(255, 255, 255, 0.75);
      color: rgba($color: #2F3236, $alpha: 0.5);
    }
    .social-medias {
      padding: 0 90px;
      text-align: center;
    }
  }
}
