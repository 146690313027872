@import "@/assets/styles/variable.scss";

.service-term {
  width: 900px;
  margin: 60px auto;
  font-size: 14px;
  color: $font-color;
  .service-term-title {
    height: 72px;
    line-height: 72px;
    font-size: 56px;
    color: #0072A7;
    font-weight: 700;
  }
  .service-term-date {
    height: 20px;
    line-height: 20px;
    font-size: 14px;
    color: $font-color-light;
    margin-bottom: 12px;
  }
  .service-term-description {
    text-indent: 12px;
    line-height: 20px;
  }
  .service-term-list {
    .service-term-item {
      margin: 12px 0;
      .service-term-item-title {
        height: 40px;
        line-height: 40px;
        font-size: 20px;
        color: #0072A7;
        font-weight: 700;
      }
      .service-term-item-content {
        & > p {
          text-indent: 12px;
          line-height: 20px;
          margin-bottom: 12px;
        }
        span.font-color-red {
          color: red;
        }
      }
    }
  }

  @media #{$mobile} {
    width: 100%;
    padding: pv(24px);
    .service-term-title, .service-term-item-title{
      height: auto !important;
    }
  }
}
