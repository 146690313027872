@import "@/assets/styles/variable.scss";

.account-verify {
  .des {
  }
  .bind-item {
    padding: 16px;
    display: flex;
    font-size: 18px;
    background: $profile-bg-color;
    border-radius: 4px;
    margin: 16px 0;
    &.phone {
      background: #eeffef;
    }
    &.fb {
      background: #ecf6ff;
    }
    &.gl {
      background-color: #ffefef;
    }
    .info {
      margin: 0 auto 0 12px;
      min-width: 10px;
    }
    .ant-btn {
      width: 90px;
      height: 42px;
    }
  }
}
