@import "@/assets/styles/variable.scss";

.account-acu-profile {
  position: relative;
  .account-profile {
    width: 80%;
    background: #fff;
    padding: 32px 32px 100px;
  }

  @media screen and (max-width: 960px) {
    width: 100%;
    .account-profile {
      width: 100%;
      padding: pv(16px);
    }
  }
}
