@import "@/assets/styles/variable.scss";

.booking-card-form{
  .payment-section-title{
    display: flex;
    justify-content: space-between;
    margin-bottom: 50px;
    .title-left{
      .title{
        font-size: 18px;
        line-height: 28px;
        font-weight: 700;
      }
      .description{
        color: #aaa;
      }
    }
  }
  .choose-add{
    margin-bottom: 32px;
  }
  .policy-content{
    margin-bottom: 32px;
    color: #aaa;
    font-size: 16px;
  }
}