@import "@/assets/styles/variable.scss";
@import "@/web/pages/ftl/acu_area.scss";
.account-profile {
  .upload-avatar-wrapper {
    text-align: left;
    margin: 36px 0;
    .upload-avatar {
      display: flex;
      align-items: center;
      .text-link {
        margin: 0 0 0 16px;
      }
    }
  }
  .self-introduction{
    .section-title{
      font-size: 18px;
      font-family: $body-font-merriweather;
      font-weight: bold;
    }
  }
  .profile-form {
    width: 100%;
    .ant-form {
      width: 100%;
    }
  }
  .acu-expertise-area{
    width: 100%;
    .select-tags-title,
    .treatment-techniques-title
    {
      font-size: 18px;
      height: auto;
      line-height: 1.2;
      color: black;
    }
  }
  @media #{$mobile} {
    .upload-avatar-wrapper {
      text-align: center;
      .upload-avatar {
        display: block;
      }
    }
  }
}
