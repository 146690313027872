@import "@/assets/styles/variable.scss";

.select-role {
  width: 924px;
  margin: 116px auto 0;
  .select-role-title {
    line-height: 56px;
    margin-bottom: 32px;
    text-align: center;
    font-size: 44px;
    color: #46604E;
    font-family: $body-font-merriweather;
    font-weight: bold;
  }
  .select-role-desc {
    height: 20px;
    line-height: 20px;
    margin-bottom: 80px;
    text-align: center;
    font-size: 14px;
    color: #696974;
  }
  .role-cards {
    width: 852px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    .select-role-card {
      width: 264px;
      height: 230px;
      cursor: pointer;
      background-size: contain !important;
      &:first-child {
        margin-right: 60px;
      }
      &.select-role-card-acupuncturist-unchecked {
        background: url("../../../assets/images/role-acupuncturist-unchecked.svg") no-repeat;
      }
      &.select-role-card-acupuncturist-checked {
        background: url("../../../assets/images/role-acupuncturist-checked.svg") no-repeat;
      }
      &.select-role-card-acupuncturist-disabled {
        background: url("../../../assets/images/role-acupuncturist-disabled.svg") no-repeat;
        cursor: not-allowed;
      }
      &.select-role-card-user-unchecked {
        background: url("../../../assets/images/role-user-unchecked.svg") no-repeat;
      }
      &.select-role-card-user-checked {
        background: url("../../../assets/images/role-user-checked.svg") no-repeat;
      }
      &.select-role-card-user-disabled {
        background: url("../../../assets/images/role-user-disabled.svg") no-repeat;
        cursor: not-allowed;
      }
      // &.select-role-card-clinic-administrator-unchecked {
      //   background: url("../../../assets/images/role-clinicadmin-unchecked.svg") no-repeat;
      // }
      // &.select-role-card-clinic-administrator-checked {
      //   background: url("../../../assets/images/role-clinicadmin-checked.svg") no-repeat;
      // }
      // &.select-role-card-clinic-administrator-disabled {
      //   background: url("../../../assets/images/role-clinicadmin-disabled.svg") no-repeat;
      //   cursor: not-allowed;
      // }
    }
  }
  .select-role-continue {
    margin-top: 88px;
    margin-bottom: 200px;
    text-align: center;
    button {
      width: 232px;
      height: 56px;
      font-size: 16px;
      font-weight: 700;
      letter-spacing: 0.5px;
    }
  }
  .button-group {
    text-align: center;
    button {
      width: 232px;
      height: 56px;
      font-size: 16px;
      font-weight: 700;
      letter-spacing: 0.5px;
      & + button {
        margin-left: 28px;
      }
    }
  }

  @media #{$mobile} {
    width: 100%;
    margin: 0;
    padding: pv(100px 24px);
    .select-role-title{
      height: auto;
      line-height: 1.2;
      font-size: 32px;

    }
    .button-group {
      button{
        &+button{
          margin: pv(16px 0);
        }
      } 
    }
    .role-cards{
      width: 100%;
      display: block;
      .select-role-card {
        width: 100%;
        margin-bottom: pv(24px);
        height: pv(84px);
        padding: pv(32px 24px);
        font-size: 14px;
        position: relative;
        .check-ico{
          position: absolute;
          right: pv(24px);
          top: pv(32px);
          visibility: hidden;
        }
        &-user,&-acupuncturist{
          &-unchecked {
            background: #ECF2EE !important;
            color: #46604E;
          }
          &-checked {
            background: #46604E !important;
            color: #ECF2EE;
            .check-ico{
              visibility: visible !important;
            }
          }
        }
      }
    }
  }
}
