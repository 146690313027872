@import "@/assets/styles/variable.scss";
@import "./acu_profile.scss";

.clinic-profile-form {
  padding-bottom: 100px;
  .common-title-of-profile {
    .profile-page-title.creating {
      color: $theme-orange !important;
    }
  }
  .owner-tip {
    display: flex;
    justify-content: space-between;
    text-align: right;
    margin-bottom: 24px;
    color: $theme-green;
    .link {
      cursor: pointer;
      font-weight: bold;
      text-decoration: underline;
    }
  }
  .picture-list {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
    .divider-br{
      display: block;
      width: 100px;
      flex: 0 0;
    }
    .ant-upload.picture-drop {
      width: 270px;
      height: 136px;
      margin: 12px;
      .anticon.anticon-camera {
        color: $font-color-light;
        font-size: 30px;
      }
      .upload-drop {
        height: 20px;
        line-height: 20px;
        font-size: 14px;
        color: $font-color-light;
        margin-top: 10px;
      }
    }
    .picture-item {
      width: 270px;
      height: 136px;
      border-radius: 6px;
      margin: 12px;
      overflow: hidden;
      position: relative;
      // cursor: pointer;
      img {
        width: 100%;
        height: 100%;
      }
      .picture-item-modal {
        display: none;
        text-align: center;
        background: rgba(0, 0, 0, 0.5);
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 99;
        span.anticon {
          font-size: 20px;
          color: #ffffff;
          cursor: pointer;
          position: relative;
          top: 58px;
          & + span.anticon {
            margin-left: 20px;
          }
        }
      }
      &:hover {
        .picture-item-modal {
          display: block;
        }
      }
    }
  }
  .upload-tip {
    height: 20px;
    line-height: 20px;
    font-size: 14px;
    color: $font-color-light;
  }
}
