@import "@/assets/styles/variable.scss";

.search-page-new {
    height       : 100%;
    background   : #fbf8f5;
    border-bottom: 1px solid #eaeaea;
    display      : flex;
    padding      : 64px 90px 64px;

    @media #{$mobile} {
        padding: pv(24px 0);
    }
    .aside {
        padding   : 24px 32px;
        position  : relative;
        flex      : 1;
        background: #fff;

        h1 {
            font-family: Merriweather;
        }

        h3 {
            font-weight: 900;
        }

        .search-input {
            padding: 0;

            input.ant-input {
                padding  : 6px 12px !important;
                height   : 42px !important;
                font-size: 16px;
            }
        }

        .search-btn {
            width     : 100%;
            margin-top: 12px;
        }
        .expertise-select{
            .ant-select-selector{
                height: auto !important;
                min-height: 48px;
                .ant-select-selection-overflow{
                    height: 100%;
                    overflow: hidden;
                }
                .ant-select-selection-item{
                    line-height: 1.5 !important;
                }
            }
        }

        .viewing-area{
            span{
                display: inline-block;
                &:first-of-type{
                    width: 32px;
                    color: $theme-green;
                }
                &.point{
                    color: #777;
                }
            }
        }

        @media #{$mobile} {
            position: absolute;
            top     : pv(92px);
            bottom  : 1rem;
            left: 0;
            width   : 100%;
            height: 100%;
            padding: pv(16px 90px);
            z-index: 9;
            .search-btn{
                display: none;
            }
            .mb-apply-btn {
                margin-top: pv(32px);
                width: 100%;
            }
        }


    }

    main.main {
        flex       : 3;
        margin-left: 16px;
        padding    : 16px;
        background : #fff;

        .top-bar {
            width        : 100%;
            display      : flex;
            padding-bottom: 6px;
            margin-bottom: 12px;
            border-bottom: 2px solid #dedede;
            .keywords {
                // margin-left: auto;
            }

            .sort-by {
                display    : flex;
                align-items: center;
                margin-left: auto;
                &>span {
                    width    : 80px;
                    font-size: 16px;
                }
                .ant-select{
                    width: 232px;
                    @media #{$mobile} {
                        width: pv(160px);
                    }
                }
            }
            .ant-btn{
                height: 48px;
            }

            .panel-toggle {
                margin-left: 12px;
                display    : flex;

                .ant-radio-button-wrapper {
                    width          : 48px;
                    height         : 48px;
                    margin         : 0 6px;
                    display        : flex;
                    align-items    : center;
                    justify-content: center;
                }
            }
        }
        .main-list{
            .no-data {
                margin-top: 48px;
                text-align: center;
                .no-data-img {
                  img {
                    width: 328px;
                    height: 240px;
                  }
                }
                .no-data-tip {
                  margin-top: 40px;
                  height: 32px;
                  line-height: 32px;
                  font-size: 24px;
                  font-weight: 700;
                  font-family: $body-font-merriweather;
                  color: #aaa;
                }
                .no-data-description{
                    color: #aaa;
                    font-weight: bold;
                    font-size: 18px;
                }
               
              }
        }
        .card-list {
            max-height: calc(100vh - 312px);
            overflow-y: auto;
            .search-result-card{
                margin-bottom: 12px;
            }
        }
        .result-pagination{
            margin-top: 12px;
            text-align: center;
        }

        .mb-search-btns{
            margin: pv(6px 0 16px);
            text-align: right;
            display: flex;
            .mb-search-input{
                flex: 2;
                height: pv(48px);
            }
            .ant-btn{
                height: pv(48px);
                &:nth-of-type(2){
                    margin-left: pv(16px);
                }
            }
        }

        @media #{$mobile} {
            margin: 0;
            width: 100%;
            .card-list{
                max-height: unset;
            }
        }
    }
}

.search-sldr-tip {
    color: $theme-green;
}