@import "@/assets/styles/variable.scss";

.booking-page {
  background-color: $profile-bg-color;
  padding: 52px 330px;
  position: relative;
  .back {
    margin-bottom: 42px;
    cursor: pointer;
    span {
      margin-right: 12px;
    }
    font-family: Proxima Nova;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
  }
  .fill-step {
    .ant-steps-item {
      &.ant-steps-item-active {
        .ant-steps-item-container {
          .ant-steps-item-icon {
            background-color: $theme-green;
            border-color: $theme-green;
          }
        }
      }
    }
  }
  .status{
    color: $theme-green;
    font-size: 16px;
    position: absolute;
    top: 196px;
    right: 330px;

    .ant-tag{
      margin-left: 8px;
    }
  }
  .booking-section {
    margin-top: 120px;
    position: relative;
    padding: 40px 32px 20px;
    background: #fff;
    .section-title {
      font-family: Merriweather;
      font-size: 32px;
      font-weight: 700;
      line-height: 40px;
      letter-spacing: 0px;
      text-align: left;
      position: absolute;
      top: -80px;
      left: 0;
    }
  }

  .btns {
    padding: 6px 12px 32px;
    display: flex;
    background-color: #fff;
    .ant-btn {
      height: 52px;
      min-width: 90px;
      margin-left: 16px;
      @media #{$mobile} {
        margin-left: pv(8px);
      }
      &:first-child {
        margin-left: auto;
      }
    }
  }
  @media #{$mobile} {
    width: 100%;
    padding: pv(50px 32px);
    .status{
      top: pv(246px);
      right: unset;
      left: pv(32px);
    }
    .booking-section {
      padding: pv(16px);
      .section-title {
        top: pv(-90px);
      }
    }
  }
}
