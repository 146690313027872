.auto-complete-place {
    width: 100%;
    position: relative;
    .location-search-input {
  
    }
    .autocomplete-dropdown-container {
      position: absolute;
      z-index: 99;
      left: 0;
      top: 50px;
      width: 100%;
      background: #ffffff;
      box-shadow: 3px 3px 6px #E5E5E5;
      border-radius: 6px;
      .loading {
        padding: 10px 14px;
      }
      .suggestion-item, .suggestion-item--active {
        width: 100%;
        height: 40px;
        line-height: 40px;
        padding: 0 14px;
        cursor: pointer;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
      .suggestion-item {
        &:first-child {
  
        }
        &:last-child {
  
        }
      }
    }
  }
  