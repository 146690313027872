@import "@fontsource/merriweather";
@import "@fontsource/roboto";

// Color
$theme-green     : #56A785;
$font-green      : #46604E;
$link-blue       : #2F80ED;
$theme-orange    : #E69F6C;
$font-color      : #2F3236;
$font-color-light: rgba(47, 50, 54, 0.5);
$profile-bg-color: #FBF8F5;

// Size
$header-height: 92px;
$header-height-mobile: 92px;
$footer-height: 128px;
$input-height : 48px;

$body-font-roboto      : 'Roboto';
$body-font-merriweather: "Merriweather";

$mobile: " screen and (max-width: 768px)";

@function pv($list) {
    $res: ();
    @each $var in $list {
        @if unit($var)=='px' {
            $res: append($res, $var / 375px * 100vw);
        } @else {
            $res: append($res, $var);
        }
    }
    @if length($res) == 1 {
        @return nth($res,1);
    }
    @return $res;
}

@mixin text-ellipsis{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-clamp: 2;
}