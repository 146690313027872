@import "@/assets/styles/variable.scss";

.fma-homepage {
  position: relative;
  .section {
    .section-block {
      display: inline-block;
      width: 45px;
      height: 5px;
      background: #da9868;
    }

    &.homepage-banner-section {
      background: url("../../../assets/images/banner-bg.png") no-repeat;
      background-size: cover;
      .homepage-banner {
        width: 100%;
        height: 800px;
        margin: 0 auto;
        padding: 232px 147px 0;
        .banner-title {
          width:878px;
          margin-bottom: 28px;
          font-size: 48px;
          font-weight: 700;
          color: #ffffff;
          @media #{$mobile} {
            height: auto;
            width: 90%;
          }
        }
        .banner-description {
          height: 20px;
          line-height: 20px;
          margin-top: 32px;
          font-size: 14px;
          color: rgba(255, 255, 255, 0.75);
        }
      }
    }

    &.our-statistics-section {
      background: linear-gradient(180deg, #fff9f3 0%, #d6c7b5 100%), #f7eada;
      .our-statistics {
        width: 100%;
        height: 950px;
        padding: 120px 147px 0;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        .our-statistics-left {
          width: 392px;
          .title {
            height: 168px;
            line-height: 56px;
            margin-bottom: 48px;
            font-size: 40px;
            font-weight: 700;
            font-family: $body-font-merriweather;
            color: #46604e;
          }
          .description {
            line-height: 48px;
            margin-bottom: 16px;
            font-size: 36px;
            color: rgba(70, 96, 78, 0.7);
          }
        }
        .our-statistics-middle {
          width: 214px;
          .section-block-container {
            height: 5px;
            margin: 32px 0;
          }
          .title {
            height: 40px;
            line-height: 40px;
            font-family: $body-font-merriweather;
            font-size: 32px;
            font-weight: 700;
            color: #46604e;
          }
          .our-statistics-item {
            margin-top: 48px;
            .ant-skeleton {
              width: 40px;
              height: 48px;
              margin-bottom: 14px;
              .ant-skeleton-title {
                height: 32px;
                margin: 8px 0;
              }
            }
            .num {
              height: 48px;
              line-height: 48px;
              margin-bottom: 14px;
              font-size: 48px;
              color: #46604e;
              font-weight: 700;
            }
            .name {
              height: 18px;
              line-height: 18px;
              font-size: 12px;
              letter-spacing: 0.5px;
              text-transform: uppercase;
              color: rgba(70, 96, 78, 0.5);
            }
          }
        }
        .our-statistics-right {
          width: 362px;
          .img-container {
            width: 100%;
            height: 496px;
            img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }

    &.find-acupuncturists-section {
      width: 90%;
      height: 738px;
      padding: 86px 74px 100px;
      background: linear-gradient(180deg, #46604e 0%, #3c5644 100%);
      position: absolute;
      top: 1536px;
      left: 50%;
      transform: translateX(-50%);
      .section-block-container {
        height: 5px;
        text-align: center;
        margin-bottom: 32px;
      }
      .section-title {
        height: 56px;
        line-height: 56px;
        text-align: center;
        margin-bottom: 80px;
        font-family: $body-font-merriweather;
        font-size: 48px;
        color: #ffffff;
        font-weight: 700;
      }
      .acupuncturist-list {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-bottom: -20px;
        .acupuncturist-skeleton {
          width: 282px;
          .ant-skeleton {
            width: 230px;
          }
        }
        .acupuncturist-item {
          width: 282px;
          height: 20px;
          line-height: 20px;
          margin-bottom: 20px;
          font-size: 14px;
          color: #ffffff;
          cursor: pointer;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    &.values-delivered-section {
      background: linear-gradient(0deg, #fff9f2, #fff9f2), #f7eada;
      .values-delivered {
        width: 100%;
        padding: 648px 147px 124px;
        margin: 0 auto;
        .section-title {
          height: 56px;
          line-height: 56px;
          margin: 32px 0 104px;
          font-size: 48px;
          color: #46604e;
          font-weight: 700;
        }
        .values-list {
          .values-item {
            display: flex;
            & + .values-item {
              margin-top: 128px;
            }
            .values-item-left {
              width: 294px;
              border-right: 1px solid rgba(47, 50, 54, 0.2);
              .values-item-left-img {
                margin-bottom: 24px;
                img {
                  width: 40px;
                  height: 40px;
                }
              }
              .values-item-left-role {
                height: 40px;
                line-height: 40px;
                font-size: 32px;
                text-transform: uppercase;
                color: $font-color;
                font-weight: 700;
              }
              .register-your-profile {
                display: inline-block;
                height: 24px;
                line-height: 24px;
                margin-top: 72px;
                font-weight: 700;
                text-transform: uppercase;
                color: #da9868;
                letter-spacing: 0.5px;
                cursor: pointer;
                &:hover {
                  text-decoration: underline;
                }
                img {
                  width: 16px;
                  height: 16px;
                  margin-left: 16px;
                  position: relative;
                  top: -2px;
                }
              }
            }
            .values-item-right {
              display: flex;
              flex-wrap: wrap;
              width: calc(100% - 295px);
              padding-left: 50px;
              margin-bottom: -56px;
              .values-right-item {
                width: 366px;
                display: flex;
                justify-content: space-between;
                margin-left: 30px;
                margin-bottom: 56px;
                .values-right-item-img {
                  width: 72px;
                  img {
                    width: 72px;
                    height: 60px;
                  }
                }
                .values-right-item-info {
                  width: 270px;
                  .title {
                    line-height: 32px;
                    margin-bottom: 8px;
                    font-size: 24px;
                    color: $font-color;
                    font-weight: 700;
                  }
                  .desc {
                    line-height: 28px;
                    font-size: 16px;
                    color: rgba(47, 50, 54, 0.7);
                  }
                }
              }
            }
          }
        }
      }
    }

    &.doctor-list-section {
      // height: 654px;
      background: #36352b;
      .doctor-list {
        width: 100%;
        height: 100%;
        margin: 0 auto;
        display: flex;
        .doctor-item {
          flex: 1;
          height: 100%;
          .doctor-item-img {
            width: 100%;
            height: 490px;
            position: relative;
            &:hover {
              .doctor-avatar-desc {
                display: block;
              }
            }
            img {
              width: 100%;
              height: 100%;
            }
            .doctor-avatar-desc {
              display: none;
              position: absolute;
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
              background: rgba(45, 60, 27, 0.85);
              padding: 64px 48px;
              font-family: $body-font-merriweather;
              font-size: 18px;
              line-height: 28px;
              color: #ffffff;
              font-weight: 700;
              cursor: pointer;
            }
          }
          .doctor-item-info {
            // padding: 24px 35px;
            padding: 24px 8px;
            .doctor-name {
              height: 32px;
              line-height: 32px;
              margin-bottom: 4px;
              font-family: $body-font-merriweather;
              font-size: 18px;
              color: #eaeef0;
              font-weight: 400;
              width: 100%;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            .doctor-description {
              width: 100%;
              height: 18px;
              line-height: 18px;
              font-size: 12px;
              letter-spacing: 0.5px;
              color: #eaeef0;
              opacity: 0.5;
              text-transform: uppercase;
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
            }
          }
          &.doctor-item-more {
            .doctor-item-img {
              position: relative;
              background: #ffffff;
              &:hover {
                .doctor-avatar-desc {
                  display: none;
                }
              }
              .more-to-come {
                height: 32px;
                line-height: 32px;
                color: $font-color-light;
                font-size: 18px;
                text-align: center;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
              }
            }
          }
        }
      }
    }
  }
}

@media #{$mobile} {
  .fma-homepage {
    .section {
      &.homepage-banner-section {
        .homepage-banner {
          width: 100%;
          height: 600px;
          padding: 108px 24px 0;
          .banner-title {
            font-size: 42px;
            line-height: 48px;
            margin-bottom: 8px;
          }
        }
      }
      &.our-statistics-section {
        .our-statistics {
          width: 100%;
          height: auto;
          padding: 48px 24px 68px;
          display: block;
          .our-statistics-left {
            width: 100%;
            margin-bottom: 48px;
            .title {
              line-height: 40px;
              font-size: 32px;
              margin-bottom: 16px;
            }
            .description {
              width: 100%;
            }
          }
          .our-statistics-middle {
            width: 100%;
            margin-bottom: 32px;
            .our-statistics-item {
              margin-top: 32px;
            }
          }
          .our-statistics-right {
            width: 100%;
          }
        }
      }
      &.find-acupuncturists-section {
        width: 100%;
        height: auto;
        padding: 48px 24px;
        position: static;
        top: auto;
        left: auto;
        transform: none;
        .section-block-container {
          text-align: left;
        }
        .section-title {
          height: auto;
          line-height: 40px;
          font-size: 32px;
          text-align: left;
          margin-bottom: 32px;
        }
        .acupuncturist-list {
          .acupuncturist-skeleton {
            width: 100%;
            max-width: 327px;
            .ant-skeleton {
              width: 100%;
            }
          }
          .acupuncturist-item {
            width: 100%;
            max-width: 327px;
          }
        }
      }
      &.values-delivered-section {
        height: 100%;
        .values-delivered {
          width: 100%;
          padding: 48px 24px 44px;
          .section-title {
            height: auto;
            line-height: 40px;
            font-size: 32px;
            margin-bottom: 68px;
          }
          .values-list {
            .values-item {
              display: block;
              & + .values-item {
                //for doctor
                margin-top: 0;
                .values-item-left {
                  .values-item-left-role {
                    margin-bottom: 16px;
                  }
                  .register-your-profile {
                    margin-top: 0;
                    margin-bottom: 44px;
                  }
                }
                .values-item-right {
                  margin-bottom: 0;
                }
              }
              .values-item-left {
                border-right: none;
                .values-item-left-img {
                  margin-bottom: 40px;
                }
                .values-item-left-role {
                  margin-bottom: 48px;
                }
              }
              .values-item-right {
                width: 100%;
                padding-left: 0;
                margin-bottom: 68px;
                .values-right-item {
                  width: 100%;
                  margin-left: 0;
                  margin-bottom: 24px;
                  .values-right-item-img {
                    margin-right: 30px;
                  }
                }
              }
            }
          }
        }
      }
      &.doctor-list-section {
        .ant-carousel {
          height: 100%;
          .slick-slider.doctor-list-responsive {
            height: 100%;
          }
          .slick-dots.slick-dots-bottom {
            li {
              width: 8px;
              height: 8px;
              margin: 0 10px;
              //border-radius: 8px;
              button {
                width: 100%;
                height: 100%;
                border-radius: 8px;
              }
              &.slick-active {
                width: 16px;
                height: 16px;
                //border-radius: 16px;
                button {
                  width: 100%;
                  height: 100%;
                  border-radius: 16px;
                  position: relative;
                  top: -4px;
                }
              }
            }
          }
        }
        .doctor-list-responsive {
          .doctor-item {
            .doctor-item-img {
              position: relative;
              img {
                width: 100%;
                height: 100%;
              }
              .doctor-avatar-desc {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                background: rgba(45, 60, 27, 0.65);
                padding: 64px 48px;
                font-family: $body-font-merriweather;
                font-size: 18px;
                line-height: 28px;
                color: #ffffff;
                font-weight: 700;
              }
            }
            .doctor-item-info {
              margin-top: 24px;
              .doctor-name {
                height: auto;
                line-height: 32px;
                margin-bottom: 4px;
                padding: 0 30px;
                font-family: $body-font-merriweather;
                font-size: 24px;
                color: #eaeef0;
                font-weight: 700;
              }
              .doctor-description {
                width: 100%;
                height: 18px;
                line-height: 18px;
                padding: 0 30px;
                font-size: 12px;
                letter-spacing: 0.5px;
                color: #eaeef0;
                opacity: 0.5;
                text-transform: uppercase;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
              }
            }
          }
        }
      }
    }
  }
}
