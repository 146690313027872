@import '@/assets/styles/variable.scss';

.ftl-progress-wrapper {
    background: #fbf8f5;
    height: 100px;
    .ftl-progress-content {
      width: 950px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 100%;
      .bar-wrapper {
        .ant-progress-line {
          width: 400px;
        }
        .info {
          font-size: 14px;
          letter-spacing: 3.5px;
          font-weight: bold;
          color: $theme-green;
          margin-left: 24px;
        }
      }
      .btn-wrapper {
        .ant-btn {
          width: 98px;
          height: 38px;
          margin-left: 10px;
        }
        
      }
    }
    @media #{$mobile} {
      height: auto;
      .ftl-progress-content{
        width: 100%;
        display: block;
        .bar-wrapper {
          width: 100%;
          text-align: center;
          .ant-progress-line{
            width: 90%;
          }
        }
        .btn-wrapper{
          margin: pv(16px);
          display: flex;
          justify-content: space-around;
        }
      }
    }
  }