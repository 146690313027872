@import "@/assets/styles/variable.scss";

.common-calendar {
  position: relative;

  .chosen-time,
  .chosen-tip {
    font-size      : 24px;
    font-weight    : bold;
    color          : #444;
    padding        : 0 12px 16px;
    display        : flex;
    justify-content: space-between;

    &.chosen-time {
      .time {
        font-weight: normal;
      }
    }
  }

  .react-calendar {
    background   : $profile-bg-color;
    width        : 100%;
    border       : none;
    margin-bottom: 12px;

    .react-calendar__navigation {
      padding: 12px 4% 4px;

      button:hover {
        color     : $theme-green;
        background: #ffffff;
      }

      .react-calendar__navigation__arrow {
        font-weight: 700;
        font-size  : 16px;
      }
    }

    .react-calendar__month-view__days {
      .react-calendar__tile.react-calendar__month-view__days__day {
        $size: 48px;

        abbr {
          width        : $size;
          height       : $size;
          line-height  : $size;
          display      : inline-block;
          border-radius: 50%;
        }

        &.react-calendar__tile--now {
          background: transparent;

          abbr {
            background: #f5d5a5;
          }
        }

        &:not([disabled]) {
          &:hover {
            background: transparent;

            abbr {
              background: #9ee7c9;
            }
          }

          &.react-calendar__tile--active {
            background: transparent;

            abbr {
              background: $theme-green;
            }
          }
        }
      }
    }
  }

  .book-list {
    margin-bottom: -8px;

    .am-pm {
      display    : flex;
      align-items: center;
      flex-wrap  : wrap;
      padding    : 0.5em 0 0 3em;
      position   : relative;

      .title {
        position: absolute;
        width   : 3em;
        top     : 0.5em;
        left    : 0;
      }

      .book-item {
        margin     : 0 8px 8px;
        background : #ffffff;
        height     : 36px;
        line-height: 34px;
        padding    : 0 6px;
        border     : 1px solid #b5b5be;
        cursor     : pointer;
        text-align : center;
        font-size  : 14px;
        color      : $font-color;

        &:hover,
        &.selected {
          color       : #449086;
          font-weight : 700;
          border-color: #449086;
          background  : #f0fbf9;
        }

        &.disabled,
        &.disabled:hover {
          cursor      : not-allowed;
          background  : #ffffff;
          font-weight: normal;
          color       : $font-color-light;
          border-color: rgba(181, 181, 190, 0.5);
        }
      }
    }

    .no-times {
      padding    : 1em 2em;
      font-weight: bold;
      font-size  : 20px;
      color      : $font-color-light;
    }
  }

  .ant-btn {
    height    : 56px;
    margin-top: 24px;
    font-size : 16px;
  }
}