
@media screen and (min-width: 1137px) and (max-width: 1380px) {
  html{
    font-size: 14px;
  }
}

@media screen and (min-width: 769px) and (max-width: 1136px) {
  html{
    font-size: 12px;
  }
}

@media screen and (max-width: 768px) {
  html{
    font-size: 14px;
  }
}