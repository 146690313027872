@import "@/assets/styles/variable.scss";

.claim-profile {
  width: 920px;
  margin: 116px auto;
  .title {
    height: 112px;
    line-height: 56px;
    text-align: center;
    font-size: 48px;
    font-weight: 700;
    color: #46604E;
  }
  .card {
    width: 754px;
    height: 248px;
    margin: 56px auto 0;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
    .handle-zoom {
      height: 96px;
      padding: 32px 24px;
      background: #FAFAFA;
      display: flex;
      justify-content: space-between;
      & > span {
        &.title {
          height: 32px;
          line-height: 32px;
          font-size: 24px;
          color: $font-color;
          font-weight: 700;
        }
      }
      .discard{
        font-weight: bold;
        span{
          margin-left: 6px;
        }
        .btn{
          text-decoration: underline;
          color: red;
          cursor: pointer;
        }
      }
    }
    .detail-zoom {
      padding: 32px 24px;
      display: flex;
      justify-content: space-between;
      .detail-avatar {
        height: 88px;
      }
      .detail-infos {
        width: 486px;
        position: relative;
        .detail-infos-sub {
          display: flex;
          justify-content: space-between;
          .detail-infos-left {
            width: calc(100% - 140px);
            .name {
              height: 28px;
              line-height: 28px;
              font-size: 18px;
              font-weight: 500;
              color: #2C2F46;
              letter-spacing: 0.5px;
            }
            .medical-title {
              height: 18px;
              line-height: 18px;
              font-size: 12px;
              font-weight: 700;
              color: #2C2F46;
              opacity: 0.5;
              letter-spacing: 0.5px;
            }
          }
          .detail-infos-right {
            width: 140px;
            text-align: right;
            .reviews {
              height: 20px;
              line-height: 20px;
              font-size: 14px;
              color: #696974;
            }
          }
        }
        .detail-infos-sup {
          width: 100%;
          height: 20px;
          line-height: 20px;
          color: #696974;
          font-size: 14px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          position: absolute;
          bottom: 0;
        }
      }
      .detail-btn {
        width: 100px;
        button {
          width: 100px;
          height: 44px;
        }
      }
    }
  }

  @media #{$mobile} {
    width: 100%;
    margin: 0;
    padding: pv(35px 24px);
    .title{
      height: auto;
      font-size: 32px;
    }
    .card{
      width: 100%;
      height: auto;
      margin: pv(32px 0);
      .handle-zoom{
        height: pv(96px);
        .title{
          font-size: 18px !important;
        }
        span[role=img]{
          font-size: 18px !important;
        }
      }
      .detail-zoom{
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .detail-avatar{
          width: pv(88px);
          height: pv(88px);
        }
        .detail-infos{
          width: 100%;
          .detail-infos-sub{
            display: block;
            .detail-infos-left,.detail-infos-right{
              width: 100%;
              text-align: center;
              .name,.medical-title,.reviews{
                height: auto;
              }

            }
          }
        }

        .detail-btn{
          margin-top: pv(24px);
          &,&>button{
            width: pv(100px);
          }
        }
      }
    }
  }
}
