@import "@/assets/styles/variable";

.fma-modal.appointment-modal{
  .ant-modal-title{
    font-size: 1.5rem;
    font-weight: 700;
  }
  .add-appointment-form{
    max-height: 580px;
    overflow-y: auto;
  }
  .ant-modal-footer{
    .ant-btn{
      height: 46px;
    }
  }

  &.calendar-action-modal{
    .action-sec{
      width: 400px;
      margin: 32px auto;
      .ant-btn {
        width: 100%;
      }
      .tip{
        color: #aaa;
      }
    }
  }
}


.ant-tooltip {
  .ant-tooltip-arrow {
    .ant-tooltip-arrow-content {
      --antd-arrow-background-color: $profile-bg-color;
    }
  }
  .ant-tooltip-inner {
    background-color: #fff;
    padding: 16px;
    .grid-item-tooltip {
      .ant-tag{
        margin-bottom: 6px;
      }
      .event,
      .patient,
      .detail {
        .info {
          display: flex;
          justify-content: space-between;
          span {
            color: #2f3236;
            &:first-child {
              font-weight: bold;
              padding-right: 4px;
            }
          }
        }
      }
      .detail {
        background-color: $profile-bg-color;
        margin-top: 16px;
        padding: 6px 0;
        .info > span {
          &:first-child {
            // color: ;
          }
        }
      }
      .event {
        .info {
          display: block;
          span{
            font-weight: bold;
          }
        }
        .ant-btn{
          width: 80%;
          margin: 12px auto;
        }
      }
    }
  }
}
