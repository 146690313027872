@import "@/assets/styles/variable.scss";

.booking-finished {
  background-color: $profile-bg-color;
  padding: 52px 330px;
  height: 100%;

  .finished-summary {
    margin-inline: auto;
    margin-block: auto;
    background-color: #fff;
    padding: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .title {
      font-size: 24px;
      line-height: 1.5;
      font-weight: 700;
      margin: 36px auto 16px;
    }
    .des {
      line-height: 20px;
      text-align: center;
      margin-bottom: 52px;
      span {
        font-weight: bold;
      }
    }
    .info {
      background-color: $profile-bg-color;
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding: 22px;
      .left {
        .head {
          span {
            margin-left: 16px;
            font-size: 18px;
            color: $theme-green;
            font-weight: 700;
          }
        }
        .btm {
          .key {
            color: #aaa;
            padding-right: 12px;
          }
          .value {
            font-weight: 600;
          }
        }
      }
      .right {
        color: #2f80ed;
        span {
          margin-left: 12px;
        }
      }
    }
    .btns {
      padding: 36px 12px 0;
      display: flex;
      background-color: #fff;
      .ant-btn {
        height: 52px;
        min-width: 90px;
        margin-left: 16px;
        @media #{$mobile} {
          margin-left: pv(8px);
        }
        &:first-child {
          margin-left: auto;
        }
      }
    }
  }
  @media #{$mobile} {
    width: 100%;
    padding: pv(50px 32px);
    .finished-summary{
      padding: pv(12px);
      .title{
      }
      .des{
        line-height: pv(22px);
      }
      .info{
        display: block;
        .right{
          margin-top: pv(16px);
        }
      }
      .btns{
        width: 100%;
        flex-direction: column;
        .ant-btn{
          width: 100%;
          margin: pv(8px) 0;
        }
      }
    }
  }
}
