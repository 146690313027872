@import '@/assets/styles/variable.scss';

.page-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.page-content-wrapper {
  padding-top: $header-height;
  box-sizing: content-box;
  flex: 1;
}
