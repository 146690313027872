@import "@/assets/styles/variable.scss";

.App-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
  height: $header-height;
  background: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 147px;
  &.white-bg-header {
    background: #36352B;
    .links {
      .link {
        color: #FFFFFF;
      }
    }
  }
  .logo {
    img {
      height: 36px;
      cursor: pointer;
    }
  }
  .links {
    display: flex;
    .home-link,
    .doctor-link,
    .blog-link
    {
      display: block;
      line-height: 56px;
      margin-right: 56px;
      font-size: 16px;
      color: $font-green;
      cursor: pointer;
      &:hover {
        color: $theme-green;
      }
    }
    .login-link {
      width: 128px;
      height: 56px;
      margin-left: 20px;
      color: #fdfeff;
      font-size: 16px;
      text-transform: uppercase;
      font-weight: bold;
      background: #56a785;
      border: none;
    }
    .user-center {
      display: flex;
      padding-top: 12px;
      .user-center-ring {
        margin-top: 7px;
        margin-right: 16px;
      }
      .user-center-avatar {
        position: relative;
        cursor: pointer;
        .anticon {
          position: relative;
          top: 3px;
          left: 8px;
        }
        .header-user-center.ant-dropdown {
          width: 186px;
          top: 48px !important;
          left: -136px !important;
          .ant-dropdown-menu {
            padding: 8px 0;
            .ant-dropdown-menu-item {
              line-height: 20px;
              padding: 10px 16px;
            }
          }
        }
      }
    }
    .toggle-language {
      line-height: 56px;
      color: $font-color-light;
    }
  }
}

@media screen and (max-width: 880px) {
  .App-header {
    padding: 0 100px;
  }
}
@media screen and (max-width: 768px) {
  .App-header {
    width: 100%;
    height: pv($header-height-mobile);
    padding: pv(0 24px);
    .logo{
      img{
        width: pv(204px);
      }
    }
    .links {
      .header-menus {
        margin-top: 2px;
      }
    }
  }
  .mbl-head-menu{
    width: 100vw;
    padding: pv(24px 0);
    margin-top: pv(16px) !important;
    .ant-dropdown-menu-item{
      // color: white;
      font-size: 18px;
      padding: pv(12px 20px);
      span{
        width: 100%;
        text-align: center;
        font-size: 18px;
        color: $font-green;
      }
      &.ant-dropdown-menu-item-selected > span{
        // background-color: $theme-green;
      }
    }
  }
}
