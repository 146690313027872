@import "@/assets/styles/variable.scss";

.search-result-card {
  width: 100%;
  height: 200px;
  padding: 24px;
  background: #ffffff;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #eee;
  cursor: pointer;
  &.search-result-card__doctor {
    height: 256px;
  }
  .card-left {
    background: #56a785;
    margin-right: 12px;
    .ant-avatar {
      &, img, [role=img] {
        width: 100%;
        height: 100%;
        min-width: 144px;
        display: flex;
        justify-content: center;
        align-items: center;
        svg{
          width: 60%;
          height: 60%;
        }
      }
    }
  }
  .card-middle {
    width: calc(60% - 72px);
    position: relative;
    .card-middle-name {
      width: 100%;
      height: 32px;
      line-height: 32px;
      margin-bottom: 8px;
      font-size: 24px;
      font-weight: 700;
      color: $font-color;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    .score-infos {
      display: flex;
      align-items: center;
      .ant-rate-in-card {
        margin-right: 6px;
      }
      .review-account {
        height: 22px;
        line-height: 22px;
        font-size: 14px;
        color: $font-color-light;
        font-weight: 500;
      }
      .cured {
        font-size: 14px;
        font-weight: 500;
        color: #449086;
        img {
          width: 20px;
          height: 20px;
          margin-right: 4px;
        }
      }
      .divide-point {
        &::before {
          content: "·";
          display: inline-block;
          width: 20px;
          text-align: center;
        }
      }
    }
    .card-infos {
      position: absolute;
      bottom: 0;
      .card-infos-item {
        display: flex;
        & + .card-infos-item {
          margin-top: 6px;
        }
        &.card-infos-doctors {
          height: 32px;
          padding: 4px 0;
          .value {
            display: flex;
            .doctor-item {
              width: 24px;
              height: 24px;
              border-radius: 24px;
              overflow: hidden;
              &:hover {
                border: 2px solid #1890ff;
                line-height: 20px;
              }
              & + .doctor-item {
                position: relative;
              }
            }
            span.doctor-item {
              display: block;
              background: #61544f;
              color: #ffffff;
              line-height: 24px;
              font-size: 12px;
              font-weight: 700;
              text-transform: uppercase;
              text-align: center;
            }
            span.doctor-more {
              position: relative;
              color: $font-color;
              line-height: 22px;
              border: 1.3px solid #e7e7ed;
              background: #f5f8fc;
            }
          }
        }
        .icon {
          width: 24px;
          height: 24px;
          margin-right: 6px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .value {
          line-height: 24px;
          font-size: 14px;
          color: $font-color;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          span.agency-link {
            height: 24px;
            line-height: 24px;
            padding-left: 6px;
            font-size: 14px;
            color: $link-blue;
            cursor: pointer;
          }
          a {
            text-decoration: underline;
          }
        }
      }
    }
  }
  .card-right {
    // width: 175px;
    // flex: 1;
  }

  @media #{$mobile} {
    display: block;
    height: auto;
    &.search-result-card__doctor {
      height: auto;
    }
    .card-middle {
      width: 100%;
      margin-top: pv(24px);
      .card-middle-name {
        height: auto;
      }
      .card-infos {
        position: relative;
        margin: pv(18px 0);
        .card-infos-item .value {
          width: 100%;
          text-overflow: unset;
          overflow: unset;
          white-space: inherit;
          height: auto;
        }
      }
    }
    .card-right {
      width: pv(196px);
      button {
        width: 100%;
        height: pv(38px);
      }
    }
  }
}