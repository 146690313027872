@import "@/assets/styles/variable.scss";

.review-item {
  background-color: #fbfbfb;
  padding: 16px;
  margin-bottom: 16px;
  .detail-info-item {
    display: flex;
    .label {
      width: 120px;
      font-size: 16px;
      font-weight: bold;
    }
  }

  .review-item-summary {
    display: flex;
    justify-content: space-between;
    .summary-left {
      .to,
      .date {
        .label {
          width: 80px;
        }
      }
    }
    .score-item {
      .ant-rate-in-card {
        margin-left: 12px;
      }
    }
  }
  .description {
    margin-top: 16px;
  }

  .review-item-score {
    display: flex;
    .score-item {
      margin-right: 40px;
      .score-item-label {
        text-transform: uppercase;
        font-size: 12px;
        font-weight: bold;
        color: #b5b5be;
      }
      .score-item-num {
        font-size: 32px;
        font-weight: bold;
        color: $theme-green;
      }
    }
  }

  @media #{$mobile} {
    .score-item {
      margin: 16px 0;
    }
  }
}
