@import "@/assets/styles/variable.scss";

.information-comp {
  .information-list {
    .information-item {
      position: relative;
      padding: 32px 0;
      margin: 0 24px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      &:last-child {
        border-bottom: none;
      }
      .information-item-title {
        height: auto;
        line-height: 28px;
        margin-bottom: 16px;
        font-size: 18px;
        color: $font-color;
        font-weight: 700;
        font-family: $body-font-merriweather;
        @media #{$mobile} {
          height: auto;
        }
      }
      .information-item-link {
        height: 21px;
        line-height: 21px;
        font-size: 14px;
        color: $font-color;
        a {
          height: 21px;
          line-height: 21px;
          padding-left: 8px;
          color: $link-blue;
          &:hover {
            text-decoration: underline;
          }
        }
      }
      .information-item-content {
        .update-info {
          .update-info-link {
            padding-left: 6px;
            color: $link-blue;
            cursor: pointer;
          }
        }
        .introduction {
          line-height: 21px;
          font-size: 14px;
          color: #696974;
          span {
            padding-left: 8px;
            color: $link-blue;
            font-size: 14px;
            cursor: pointer;
          }
        }
        .expertise {
          .area-item {
            display: flex;
            padding: 11px 0;
            .icon {
              width: 16px;
              height: 20px;
              margin-right: 24px;
              img {
                width: 100%;
              }
            }
            .name, .name2 {
              flex: 1;
              line-height: 20px;
              font-size: 14px;
              color: $font-color;
            }
            .date {
              width: 80px;
              line-height: 20px;
              text-align: right;
              font-size: 14px;
              color: $font-color;
              font-weight: 700;
            }
          }
        }
        .treatment-techniques {
          .view-all {
            height: 18px;
            line-height: 18px;
            font-size: 12px;
            color: #449086;
            font-weight: 700;
            text-transform: uppercase;
            letter-spacing: 0.5px;
            position: absolute;
            top: 37px;
            right: 0;
            cursor: pointer;
            span {
              margin-right: 4px;
            }
          }
          .treatment-technique-list {
            display: flex;
            flex-wrap: wrap;
            margin-right: -22px;
            margin-bottom: -16px;
            &.show-all {
              // height: 120px; // when add more tags to 2 lines, update it to 120px
              height: 60px;
              overflow: hidden;
            }
            .treatment-technique-item {
              width: 218px;
              height: 44px;
              padding: 12px 16px;
              margin: 0 22px 16px 0;
              background: #E6EFE6;
              box-shadow: inset 2px 0px 0px #649F64;
              line-height: 20px;
              font-size: 14px;
              color: $font-color;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
          .hide-more-tags {
            text-align: right;
            padding-top: 10px;
            cursor: pointer;
            .anticon.anticon-up {
                margin-right: 6px;
            }
          }
        }
        .services {
          border: 1px solid #E9E9E9;
          .services-table-row {
            display: flex;
            border-bottom: 1px solid #E9E9E9;
            &:last-child {
              border-bottom: none;
            }
            .services-table-column {
              width: 20%;
              line-height: 20px;
              padding: 18px 0 18px 32px;
              font-size: 14px;
              font-weight: 500;
              text-align: right;
              color: $font-color;
              &:first-child {
                width: 55%;
                text-align: left;
              }

              @media #{$mobile} {
                width: 25%;
                &:first-child{
                  width: 40%;
                }
              }
            }
          }
        }
        .membership-activities {
          .membership-activities-item {
            line-height: 21px;
            margin-bottom: 21px;
            font-size: 14px;
            color: #696974;
          }
          .view-more {
            height: 20px;
            line-height: 20px;
            font-size: 14px;
            color: $link-blue;
            font-weight: 500;
            cursor: pointer;
            span {
              margin-right: 4px;
              position: relative;
              top: -1px;
            }
          }
        }
      }
      .no-data {
        .no-data-img {
          margin: 50px 0;
          text-align: center;
          img {
            width: 196px;
            height: 170px;
          }
        }
        .no-data-title {
          height: 32px;
          line-height: 32px;
          margin-bottom: 8px;
          text-align: center;
          color: #2C2F46;
          font-size: 24px;
          font-weight: 700;
          font-family: $body-font-merriweather;
        }
        .no-data-description {
          height: 20px;
          line-height: 20px;
          margin-bottom: 10px;
          text-align: center;
          color: $font-color;
          font-size: 14px;
        }
      }
    }
  }
}
