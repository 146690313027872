@import "@/assets/styles/variable.scss";

.account-reviews {
  .no-data {
    width: 100%;
    text-align: center;
    img {
      display: inline-block;
    }
    .no-data-tip {
      font-size: 24px;
      font-weight: bold;
      margin: 60px auto;
    }
  }
  .list-title {
    display: flex;
    justify-content: space-between;
    font-size: 18px;
    .title > span {
      font-weight: bold;
    }
    .filter {
      display: flex;
      border: 1px solid #d9d9d9;
      border-radius: 4px;
      & > span {
        height: 48px;
        line-height: 48px;
        padding-left: 12px;
        color: #aaa;
      }
      .ant-select {
        width: 150px;
      }
    }
  }

  @media #{$mobile} {
    .list-title {
      display: block;
      .filter {
        width: pv(190px);
        .ant-select {
          width: pv(150px);
        }
      }
    }
  }
}
