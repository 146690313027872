@import "@/assets/styles/variable.scss";

.account-clinic-doctor {
  
  .profile-form {
    width: 100%;
    .ant-form {
      width: 100%;
    }
    .clinic-role {
      font-size: 18px;
      text-align: right;
      .role{
        margin: 0 6px;
        color: $theme-green;
      }
      .ant-btn{
        height: 42px;
        width: 160px;
        border-color: $theme-green;
        margin-left: 12px;
        &.ant-btn-dangerous{
          border-color: red;
        }
      }
    }
  }
  .service-list {
    .form-item-title {
      height: 18px;
      line-height: 18px;
      margin-bottom: 8px;
      font-size: 12px;
      font-weight: 700;
      color: rgba(47, 50, 54, 0.5);
      text-transform: uppercase;
    }
    .form-row {
      display: flex;
      justify-content: space-between;
      .first-form-item {
        width: 354px;
      }
      .second-form-item {
        width: 158px;
      }
      .third-form-item {
        width: 158px;
      }
    }
    .add-btn-container {
      padding-bottom: 40px;
      .add-btn {
        height: 20px;
        line-height: 20px;
        font-size: 14px;
        font-weight: 500;
        color: #2f80ed;
        cursor: pointer;
      }
    }
  }
  @media #{$mobile} {
    width: 100%;
    margin: 0;
    .profile-form{
      .clinic-role{
        text-align: center;
      }
    }
    .service-list {
      .form-row {
        flex-wrap: wrap;
        width: 100%;
        position: relative;
        .ant-form-item {
          width: 100%;
        }
        span.anticon-close-circle {
          position: absolute !important;
          top: 0 !important;
          right: 0;
        }
        .form-column {
          &:first-child {
            width: 100%;
          }
          &:nth-child(2),
          &:nth-child(3) {
            width: 48%;
            div {
              width: 100% !important;
            }
          }
        }
      }
    }
  }
}
