
    .search-select-dropdown{
      .ant-divider-horizontal{
        margin: 0.5rem;
      }
      .ant-space {
        display: flex;
        .ant-space-item{
          &:first-child{
            width: 80%;
          }
          .ant-input{
            &.ant-input-status-error{
              border-color: #ddd;
            }
          }
        }
        .add-new-btn:not(:disabled){
          background-color: #529dff;
          color: #fff;
        }
      }
    }
.ant-select.search-select .ant-select-selector .ant-select-selection-item{
  line-height: 2 !important;
}