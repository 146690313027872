@import "@/assets/styles/variable.scss";

.verify-section {
  width: 100%;
  .button-container {
    text-align: center;
    margin-top: 50px;
    &.send-button {
      margin-top: 68px;
    }
    button {
      width: 208px;
      height: 64px;
      font-size: 16px;
      letter-spacing: 0.5px;
      font-weight: 700;
    }
  }
  .verify-email-tip {
    margin-bottom: 50px;
    & > div {
      line-height: 1;
      text-align: center;
      font-size: 48px;
      color: #46604e;
      font-weight: 700;
      &.email {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: normal;
      }
    }
  }

  .verify-code {
    width: 554px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    .ant-input {
      width: 116px;
      height: 138px !important;
      line-height: 108px;
      text-align: center;
      font-size: 48px;
      font-weight: 700;
    }
  }
  .verify-button {
    .ant-btn{
      width: 236px;
    }
  }
  .verify-resend {
    margin: 16px;
    text-align: center;
    font-size: 16px;
    .tip {
      display: inline-block;
      color: $theme-green;
      span {
        cursor: pointer;
        color: $link-blue;
      }
    }
  }

  @media #{$mobile} {
    width: 100%;
    min-width: 100%;
    margin: 0;
    padding: pv(100px 24px);
    .verify-email-tip > div {
      font-size: 32px;
      height: auto;
      line-height: 1.2;
      &.email {
        overflow: visible;
        word-wrap: break-word;
        text-decoration: underline;
      }
    }
    .verify-code {
      width: 100%;
      .ant-input {
        flex: 1;
        margin: pv(6px);
        height: pv(100px) !important;
      }
    }
  }
}
