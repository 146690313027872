@import "@/assets/styles/variable.scss";

.square-card-input {
  margin-bottom: 36px;
  .label {
    text-transform: uppercase;
    color: rgba(47, 50, 54, 0.5);
    font-weight: 700;
    line-height: 1.75;
    padding: 0 0 8px;
    &.required::before{
      display: inline-block;
      margin-right: 4px;
      color: #ff4d4f;
      font-size: 14px;
      line-height: 1;
      content: '*';
    }
  }
  .card-con,
  .loading {
    height: 48px;
    &.loading {
      border: 1px solid #eee;
      color: $theme-green;
      width: 100%;
      margin-left: 0;
    }
    .sq-card-wrapper {
      height: 100%;
      .sq-card-iframe-container {
        &,
        iframe {
          height: 100% !important;
        }
      }
      .sq-card-message{
        margin-top: 6px;
      }
    }
  }
  .ant-btn {
    height: 48px;
    margin-left: 16px;
  }
}
