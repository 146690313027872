@import "@/assets/styles/variable.scss";

.account-page {
  padding-top: $header-height;
  height: 100%;
  display: flex;
  .account-nav {
    width: 260px;
    height: 100%;
    overflow: hidden auto;
    &::-webkit-scrollbar{
      width: 8px;
      background-color: #f8f8f8;
    }
    &::-webkit-scrollbar-track{
      box-shadow: inset 0 0 4px rgba($color: #000000, $alpha: 0.2);
      border-radius: 8px;
      background-color: #dafaf0;
    }
    &::-webkit-scrollbar-thumb{
      border-radius: 8px;
      background-image : -webkit-gradient(linear, left bottom, left top, color-stop(0.44, #8feed2), color-stop(0.72, #6be0bd), color-stop(0.86, #3fb692));
    }
    
    &,
    .ant-menu-sub {
      background: #449086;
      color: white;
    }
    .ant-menu-sub {
      background: #348076;
    }
    &.ant-menu:not(.ant-menu-horizontal) {
      .ant-menu-item,
      .ant-menu-submenu > .ant-menu-submenu-title
       {
        height: 56px;
      }
      .ant-menu-item,
      .ant-menu-submenu {
        &.with-gap{
          position: relative;
          margin-bottom: 36px;
        }
      }
      .ant-menu-item:hover,
      .ant-menu-item-selected {
        background-color: #2c675f;
        color: white;
      }
      .ant-menu-submenu-title:hover,
      .ant-menu-submenu-title > .ant-menu-submenu-arrow,
      .ant-menu-submenu-selected {
        color: white;
      }
      .ant-menu-item::after {
        border-right: none;
      }
    }
  }
  .account-content {
    flex: 1;
    height: 100%;
    padding: 35px 40px;
    background-color: $profile-bg-color;
    position: relative;
    overflow-y: auto;
    .content-title {
      font-family: Merriweather;
      font-size: 32px;
      font-weight: bold;
      margin: 20px 0;
    }
    .bread-nav {
      position: absolute;
      top: 12px;
      left: 40px;
    }
    .account-content-page {
      width: 80%;
      background: #fff;
      padding: 32px 32px 60px;
      position: relative;
    }
    .submit-btn {
      margin: 32px 0;
      .ant-btn {
        height: 56px;
        min-width: 200px;
        &:not(:first-child) {
          margin-left: 16px;
        }
      }
    }
    .progress{
      position: absolute;
      top: 128px;
      right: 0px;
      width: 18%;
      padding: 16px;
      background: #EEF6F3;
      .title{
        font-size: 18px;
        font-weight: bold;
        font-family: $body-font-merriweather;
        margin-bottom: 16px;
      }
      .pos{
        position: absolute;
        width: 132px;
        text-align: center;
        top: 135px;
        left: 16px;
        color: #696974;
      }
      .des{
        margin-top: 32px;
        color: #2F3236;
      }
      .items{
        margin-block-start: 0;
        padding-inline-start: 0;
        li{
          list-style: none;
          color: #449086;
          font-weight: 700;
          margin-top: 16px;
        }
      }
    }
  }

  @media #{$mobile} {
    padding-top: pv($header-height-mobile);
    .account-nav {
      display: none;
    }
    .account-content {
      padding: pv(30px 16px);
      .bread-nav {
        left: pv(16px);
      }

      .account-content-page {
        width: 100%;
        padding: pv(16px);
      }
      .submit-btn {
        text-align: center;
        .ant-btn {
          width: 80%;
          height: pv(56px);
          margin: pv(12px 0) !important;
        }
      }
      .progress{
        visibility: hidden;
      }
    }
  }
}