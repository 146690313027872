@import "@/assets/styles//variable.scss";

.acu-expertise-area {
  width: 950px;
  padding: 40px 0;
  margin: 0 auto;
  .acu-select {
    .ant-select-selector {
      height: unset !important;
      min-height: 52px !important;
      .ant-select-selection-overflow-item {
        .ant-select-selection-item {
          height: 28px !important;
          line-height: 28px !important;
          padding: 0 8px;
          color: #ffffff;
          background-color: #69a69e;
          .ant-select-selection-item-remove {
            color: #ffffff;
          }
        }
      }
    }
    margin-bottom: 16px;
  }
  .suggestion-title,
  .input-title {
    height: 18px;
    line-height: 18px;
    margin-bottom: 8px;
    color: $font-color-light;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 0.5px;
    text-transform: uppercase;
  }
  .suggestion-tags {
    display: flex;
    flex-wrap: wrap;
    .expertise-tag {
      background: none;
      color: #69a69e;
      font-size: 14px;
      font-weight: 700;
      margin: 0 20px 5px 0;
      border: none;
      cursor: pointer;
    }
  }

  .select-tags {
    margin-bottom: 64px;
  }
  .select-tags-title,
  .treatment-techniques-title {
    height: 40px;
    line-height: 40px;
    margin-bottom: 32px;
    font-size: 32px;
    color: #46604e;
    font-weight: 700;
  }
  .ant-input.technique-detail {
  .treatment-techniques {
      height: 100px;
      padding: 14px 12px;
      line-height: 20px;
      font-size: 14px;
    }
  }
  @media #{$mobile} {
    width: 100%;
    padding: 0;
    margin: pv(0 0 40px);
    .treatment-techniques {
      .treatment-techniques-title {
        height: auto;
      }
    }
  }
}
