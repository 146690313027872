@import "@/assets/styles/variable.scss";

.profile-page {
  position: relative;
  height: 100%;
  margin: 0 auto;
  @media #{$mobile} {
    background: #FBF8F5;
  }
  .profile-banner {
    height: 258px;
    background: url("../../../assets/images/profile-banner.svg") no-repeat;
    background-size: cover;
  }
  .edit-trigger {
    width: 744px;
    height: 74px;
    padding: 27px 32px;
    background: #FFFFFF;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: space-between;
    .tips {
      height: 20px;
      line-height: 20px;
      font-size: 14px;
      color: $font-color;
      img {
        width: 16px;
        height: 16px;
        margin: 2px 8px;
      }
      span {
        height: 20px;
        line-height: 20px;
        font-size: 14px;
        color: $font-color;
        font-weight: 500;
      }
    }
    .trigger {
      height: 20px;
      line-height: 20px;
      font-size: 12px;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      color: $link-blue;
      cursor: pointer;
    }
  }
  .profile-main {
    height: calc(100% - 258px);
    background: #FBF8F5;
    padding-top: 158px;
    padding-bottom: 112px;
    @media #{$mobile} {
      padding: pv(20px 24px);
      height: auto;
    }
    .profile-main-center {
      width: 1138px;
      @media #{$mobile} {
        width: 100%;
      }
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      .profile-tabs {
        width: 754px;
        .ant-tabs {
          background: #FFFFFF;
          box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
          .ant-tabs-nav {
            margin-bottom: 0;
            background: $profile-bg-color;
            .ant-tabs-nav-list {
              .ant-tabs-ink-bar {
                display: none;
              }
              .ant-tabs-tab {
                padding: 16px 24px;
                background: rgba(255, 255, 255, 0.5);
                color: #959596;
                letter-spacing: 0.5px;
                .ant-tabs-tab-btn {
                  text-transform: uppercase;
                  font-weight: 700;
                }
                &.ant-tabs-tab-active {
                  background: #FFFFFF;
                  border-top: 4px solid #56A785;
                }
                & + .ant-tabs-tab {
                  margin-left: 0;
                }
              }
            }
          }
        }
        @media #{$mobile} {
          width: 100%;
        }
      }
      .profile-main-right {
        width: 362px;
        //background: #959596;
      }
      @media #{$mobile} {
        flex-direction: column-reverse;
        .profile-main-right {
          width: 100%;
          margin-bottom: pv(48px);
        }
        .book-tip {
          text-transform: uppercase;
          font-weight: 700;
          font-size: 12px;
        }
      }
    }
  }
}
