@import "./variable.scss";

// ant-input
input.ant-input {
  height: $input-height !important;
  border-radius: 4px;
  padding: 14px 12px !important;
  line-height: 18px;
  font-size: 14px;
}

//for search input in select-tags component
input.ant-input.search-input {
  height: 28px !important;
  border-radius: 0;
  padding: 0 !important;
  line-height: 28px;
  font-size: 14px;
}

.ant-input:focus,
.ant-input:hover,
.ant-input-focused {
  border-color: #d9d9d9 !important;
  box-shadow: none !important;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner,
.ant-radio-wrapper:hover .ant-radio-inner,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus + .ant-radio-inner {
  border-color: #39b38e !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #39b38e !important;
  border-color: #39b38e !important;
}
.ant-radio-checked .ant-radio-inner {
  border-color: #39b38e !important;
  &::after {
    background-color: #39b38e !important;
  }
}

// ant-btn
.ant-btn {
  text-transform: uppercase;
  font-weight: bold;
  border-radius: 0;
  letter-spacing: 0.5px;
  &:hover,
  &:focus {
    color: $theme-green;
    border-color: $theme-green;
  }
}
.ant-btn-primary {
  background: $theme-orange;
  border-color: $theme-orange;
  &:hover,
  &:focus {
    border-color: $theme-orange;
    background: $theme-orange;
    color: #fff;
    opacity: 0.8;
  }
}

.ant-btn-success {
  background: #449086;
  border-color: #449086;
  color: #fff;
  &:hover,
  &:focus {
    border-color: #449086;
    background: #449086;
    color: #fff;
    opacity: 0.8;
  }
  &:disabled {
    // !important, or the disabled button will be transparent when hover
    border-color: #449086 !important;
    background: #449086 !important;
    color: #fff !important;
    opacity: 0.2 !important;
  }
}

.ant-picker{
  width: 100%;
  height: 48px;
  border-radius: 4px;
}
.ant-input-number{
  width: 100%;
  .ant-input-number-input {
    width: 100%;
    height: 46px;
  }
}

.ant-select {
  width: 100%;
  .ant-select-selector {
    height: 48px !important;
    border-radius: 4px !important;
    .ant-select-selection-placeholder {
      height: 100% !important;
      line-height: 46px !important;
    }
    .ant-select-selection-item {
      height: 100% !important;
      line-height: 46px !important;
      font-size: 14px;
      font-weight: 500;
      color: #2f3236;
    }
  }
  &.ant-select-multiple {
    .ant-select-selector {
      height: unset;
    }
  }
}

.ant-select:focus,
.ant-select:hover,
.ant-select-focused {
  border-color: #d9d9d9 !important;
  box-shadow: none !important;
}

.ant-tabs {
  .ant-tabs-nav {
    // color: #39b38e;
    .ant-tabs-nav-list {
      .ant-tabs-tab {
        font-size: 16px;
        &:hover,
        &.ant-tabs-tab-active {
          .ant-tabs-tab-btn {
            color: #39b38e;
          }
        }
      }
      .ant-tabs-ink-bar {
        background: #39b38e;
      }
    }
  }
}
