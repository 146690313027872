@import "@/assets/styles/variable.scss";

.fill-profile-page {
    .profile-form{
        width: 900px;
        margin: 36px auto 60px;
        .common-title-of-profile{
          .profile-page-title{
            color: $font-green;
            font-family: $body-font-merriweather;
            font-weight: 700;
            font-size: 48px;
            height: 56px;
            line-height: 56px;
            margin-bottom: 32px;
            text-align: center;
          }
          .profile-page-description{
            color: #696974;
            font-size: 14px;
            line-height: 20px;
            height: 20px;
            margin-bottom: 56px;
            text-align: center;
          }

          @media #{$mobile} {
            .profile-page-title{
              font-size: 32px;
              height: auto;
              line-height: 1.2;
            }
            .profile-page-description{
              
            }
          }
        }
        .ant-form {
          width: 558px;
          margin: 0 auto;
          .form-item {
            &.form-item-clinicName {
              &.form-item-clinicName {
                .ant-select-selection-search-input {
                  position: relative;
                  top: 4px;
                }
              }
            }
            .form-item-label {
              height: 18px;
              line-height: 18px;
              margin-bottom: 8px;
              font-size: 12px;
              font-weight: 700;
              color: $font-color-light;
              text-transform: uppercase;
            }
            .ant-row.ant-form-item {
              &.has-unclaimed-profile {
                margin-bottom: 0;
              }
            }
            .unclaimed-profile {
              height: 20px;
              line-height: 20px;
              margin: 8px 0 24px;
              color: $link-blue;
              cursor: pointer;
            }
          }
          .ant-select {
            .ant-select-selector {
              min-height: 48px;
              height: unset !important;
              .ant-select-selection-overflow {
                .ant-select-selection-overflow-item {
                  height: 28px;
                  //position: relative;
                  .ant-select-selection-item {
                    background: #69A69E;
                    position: relative;
                    top: -2px;
                    .ant-select-selection-item-content {
                      height: 28px;
                      line-height: 28px;
                      color: #FFFFFF;
                      font-weight: 500;
                      font-size: 14px;
                    }
                    .ant-select-selection-item-remove {
                      color: #FFFFFF;
                      position: relative;
                      top: -10px;
                    }
                  }
                }
              }
            }
          }
        }
        
        @media #{$mobile} {
          width: 100%;
          margin: 0;
          .ant-form{
            width: 100%;
          }
        }
    }
}