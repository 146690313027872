@import './account_setting_security.scss';

.account-delete{
  .tips{
    margin-top: 32px;
    background-color: $profile-bg-color;
    .txt{
      font-family: $body-font-merriweather;
      font-weight: bold;
      font-size: 18px;
      margin-bottom: 16px;
    }
  }
}