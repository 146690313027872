@import '@/assets/styles/variable.scss';

.ant-upload.picture-drop {
    width: 270px;
    height: 136px;
    .anticon.anticon-camera {
      color: $font-color-light;
      font-size: 30px;
    }
    .upload-drop {
      height: 20px;
      line-height: 20px;
      font-size: 14px;
      color: $font-color-light;
      margin-top: 10px;
    }
  }