// @import '../assets/styles/rem.scss';
.global-spin-wrapper > .ant-spin-container {
  &,
  .global-spin {
    height: 100vh;
  }
}

.common-page-style {
  padding: 128px 0;
}

.App {
  .scale-wrapper {
  }
  .common-loading-container {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 9999;
    background: rgba(0, 0, 0, 0.3);
    .ant-spin {
      width: 60px;
      height: 60px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      & > span {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.ant-form-item {
  .ant-form-item-label {
    label {
      text-transform: uppercase;
      color: rgba(47, 50, 54, 0.5);
      font-weight: 700;
    }
  }
}

.ant-rate.ant-rate-in-card {
  .ant-rate-star {
    font-size: 14px;
    &:not(:last-child) {
      margin-right: 2px;
    }

    &.ant-rate-star-zero {
      .ant-rate-star-first,
      .ant-rate-star-second {
        color: #bbb;
      }
    }
  }
}