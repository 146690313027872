@import "@/assets/styles/variable.scss";

.clinic-patient-reviews {
  padding: 24px;
  .score-card-list {
    .score-card {
      width: 216px;
      height: 92px;
      padding: 16px 24px;
      background: #f5f8fc;

      .title {
        height: 18px;
        line-height: 18px;
        margin-bottom: 4px;
        font-size: 12px;
        color: #b5b5be;
        font-weight: 700;
        letter-spacing: 0.5px;
        text-transform: uppercase;
      }
      .score {
        height: 40px;
        line-height: 40px;
        font-size: 32px;
        color: #449086;
        font-weight: 700;
      }
    }

    @media #{$mobile} {
      width: 100%;
      flex-wrap: wrap;
      .score-card {
        flex: 1;
      }
    }
  }
  .score-filter {
    margin-bottom: 24px;
    .ant-select {
      .ant-select-selector {
        padding-left: 98px;
        position: relative;
        &::before {
          content: "Treated for:";
          height: 46px;
          line-height: 46px;
          font-size: 14px;
          color: $font-color-light;
          position: absolute;
          top: 0;
          left: 12px;
        }
      }
    }
  }
  .score-container {
    overflow: hidden;
    .score-container-left {
      width: 360px;
      float: left;
      .title {
        height: 18px;
        line-height: 18px;
        margin-bottom: 8px;
        font-size: 12px;
        color: #46604e;
        font-weight: 700;
        letter-spacing: 0.5px;
        text-transform: uppercase;
      }
      .score {
        height: 48px;
        line-height: 48px;
        margin-bottom: 8px;
        font-family: $body-font-merriweather;
        font-size: 48px;
        color: #449086;
        font-weight: 700;
      }
      .account {
        height: 20px;
        line-height: 20px;
        font-size: 14px;
        color: #696974;
      }
    }
    .score-container-right {
      width: 346px;
      float: right;
      .star-progress {
        display: flex;
        & + .star-progress {
          margin-top: 8px;
        }
        .score {
          width: 8px;
          height: 20px;
          line-height: 20px;
          margin-right: 4px;
        }
        .star {
          width: 19px;
          height: 20px;
          padding-right: 8px;
          position: relative;
          img {
            width: 11px;
            height: 11px;
            position: absolute;
            top: 4.4px;
          }
        }
        .ant-progress {
          height: 20px;
          .ant-progress-outer {
            position: relative;
            height: 20px;
            .ant-progress-inner {
              position: absolute;
              top: 6px;
            }
          }
        }
      }
    }
    .score-card-list {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin: 158px 0 32px 0;
    }
  }
  .reviews-filter {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
    .account {
      color: #2c2f46;
      span {
        padding-right: 4px;
        font-weight: 700;
      }
    }
    .filter {
      width: 188px;
      .ant-select.filter-sortBy {
        .ant-select-selector {
          padding-left: 68px;
          position: relative;
          &::before {
            content: "Sort by:";
            height: 46px;
            line-height: 46px;
            font-size: 14px;
            color: $font-color-light;
            position: absolute;
            top: 0;
            left: 12px;
          }
        }
      }
    }
  }
  .reviews-container {
    .review-card {
      min-height: 258px;
      padding: 16px;
      background: #fbfbfb;
      & + .review-card {
        margin-top: 16px;
      }
      &:last-child {
        margin-bottom: 10px;
      }
      .review-info {
        overflow: hidden;
        padding-bottom: 16px;
        .avatar {
          float: left;
          margin-right: 10px;
        }
        .name-date {
          float: left;
          & > div {
            min-height: 20px;
            line-height: 1.2;
            font-size: 14px;
            color: $font-color;
            &.name {
              font-weight: 700;
            }
            &.date {
            }
          }
        }
        .review-stars {
          //width: 140px;
          float: right;
        }
      }
      .review-desc {
        min-height: 92px;
        line-height: 20px;
        padding: 16px 0;
        border-top: 1px solid rgba(0, 0, 0, 0.1);
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        font-size: 14px;
        color: $font-color;
        a {
          padding-left: 6px;
        }
      }
      .review-score {
        display: flex;
        padding-top: 16px;
        .score-card {
          width: unset;
          height: unset;
          padding: 0;
          margin-right: 40px;
          background: transparent;
        }
      }

      @media #{$mobile} {
        height: auto;
        .review-desc {
          height: auto;
        }
        .review-score {
          justify-content: space-between;
          .score-card {
            margin: 0;
          }
        }
      }
    }
  }
}
.reviews-filter {
  @media #{$mobile} {
    display: block !important;
    .filter {
      width: 100% !important;
      display: block !important;
      margin-top: pv(16px);
      & > div:nth-child(2) {
        margin-top: pv(12px);
      }
    }
  }
}
