@import "@/assets/styles/variable.scss";

.booking-summary {
  .summary-section-doctor {
    display: flex;
    .doctor-avatar {
      flex: 0;
      width: 48px;
      height: 48px;
    }
    .doctor-info {
      flex: 1;
      padding-left: 12px;
      .name {
        font-size: 18px;
        font-weight: 700;
        line-height: 28px;
      }
      .title {
        font-size: 12px;
        line-height: 18px;
        text-transform: uppercase;
        color: #aaa;
      }
    }
    .doctor-book {
      text-align: right;
      .time {
        color: $theme-green;
        font-size: 18px;
        font-weight: 700;
        line-height: 28px;
      }
      .date {
        color: #aaa;
      }
    }
  }
  .summary-section-patient > .patient-item,
  .summary-section-fee,
  .summary-section-card {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    margin: 6px 0;
    .key {
      color: #aaa;
    }
    .value {
      font-weight: bold;
    }
  }
  .summary-section-fee {
    .value {
      font-size: 24px;
    }
  }
}
