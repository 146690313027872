@import "@/assets/styles/variable.scss";

.acu-experience {
  width: 754px;
  margin: 0 auto;
  .experience-title {
    height: 40px;
    line-height: 40px;
    margin-bottom: 8px;
    color: #46604E;
    font-size: 32px;
    font-weight: 700;
  }
  .experience-desc {
    height: 20px;
    line-height: 20px;
    margin-bottom: 8px;
    font-size: 14px;
    color: #2F3236;
  }

  @media #{$mobile} {
    width: 100%;
    padding: 0 !important;
    .experience-title,.experience-desc{
      height: auto;
    }

  }
}
#user-experience-form {
  .experience-section {
    padding: 32px 0;
    border-bottom: 1px solid rgba(47, 50, 54, 0.1);
    .section-title {
      height: 28px;
      line-height: 28px;
      margin-bottom: 16px;
      font-size: 18px;
      color: #2F3236;
      font-weight: 700;
      font-family: $body-font-merriweather;
    }
    .form-row {
      display: flex;
      justify-content: space-between;
    }
    .form-item-title {
      height: 18px;
      line-height: 18px;
      margin-bottom: 8px;
      font-size: 12px;
      font-weight: 700;
      color: rgba(47, 50, 54, 0.5);
      text-transform: uppercase;
    }
    textarea {
      height: 100px;
      padding: 14px 12px;
      border-radius: 4px;
    }
    //span.anticon-close-circle {
    //  position: relative;
    //  top: 40px;
    //}
    .add-btn-container {
      .add-btn {
        height: 20px;
        line-height: 20px;
        font-size: 14px;
        font-weight: 500;
        color: #2F80ED;
        cursor: pointer;
      }
    }
    .ant-picker, .ant-select, .ant-input-number {
      width: 100%;
      height: 48px;
      border-radius: 4px;
      .ant-input-number-input-wrap {
        height: 46px;
        .ant-input-number-input {
          height: 46px;
        }
      }
    }
    .ant-select {
      .ant-select-selector {
        height: 100%;
        border-radius: 4px;
        .ant-select-selection-item {
          height: 100%;
          line-height: 46px;
          font-size: 14px;
          font-weight: 500;
          color: #2F3236;
        }
      }
    }
    &.self-introduction {

    }
    &.experience-section {
      .school-list {
        margin-bottom: 16px;
      }
      .first-form-item {
        width: 354px;
      }
      .second-form-item {
        width: 158px;
      }
      .third-form-item {
        width: 158px;
      }
    }
    &.certificates {
      .form-row-certificates {
        .form-column.date {
          width: 322px;
          .second-form-item {
            width: 100%;
          }
        }
      }
      .licenses-list {
        margin-bottom: 16px;
      }
      .first-form-item {
        width: 360px;
      }
      .date-input-list {
        display: flex;
        .third-form-item {
          width: 102px;
          margin-left: 8px;
          &:first-child {
            margin-left: 0;
          }
        }
      }
    }
    &.activities {
      border: none;
      .activities-item {
        display: flex;
        justify-content: space-between;
        .ant-row.ant-form-item {
          margin-bottom: 16px;
          textarea {
            width: 700px;
          }
        }
      }
    }

    .school-list, .companies-list, .licenses-list, .certificates-list, .service-list{
      .form-row{
        .anticon-close-circle{
          position: relative;
          top: 36px;
        }
      }
    }
    @media #{$mobile} {
      .form-row{
        width: 100%;
        flex-wrap: wrap;
        position: relative;
        
      } 
      .section-title{
        height: auto;
      }
      &.activities{
        .activities-item{
          position: relative;
          .ant-row.ant-form-item{
            textarea{
              width: calc(100vw - pv(48px)) !important;
            }
          }
          span.anticon-close-circle{
            transform: translate(50%,-50%);
          }
        }
      }
      span.anticon-close-circle{
        position: absolute !important;
        top: 0 !important;
        right: 0;
      }
      .ant-row.ant-form-item {
        width: 100% !important;
      }

      .school-list, .companies-list, .service-list{
        .form-row{
          .form-column{
            &:nth-child(2),&:nth-child(3){
              width: 48%;
              div{
                width: 100% !important;
              }
            }
          }
          
        }
      }
    }
  }
}
