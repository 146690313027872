@import "@/assets/styles/variable.scss";

.booking-base-form {
  .booking-title {
    text-transform: uppercase;
    color         : rgba(47, 50, 54, 0.5);
    font-weight   : 700;
    margin-bottom : 8px;
  }

  .doctor-info {
    padding      : 16px;
    display      : flex;
    margin-bottom: 16px;
    // background: #e6efe6;
    background   : $theme-green;

    .doctor-avatar {
      flex  : 0;
      width : 48px;
      height: 48px;
    }

    .doctor-name {
      flex        : 1;
      padding-left: 16px;
      color       : #fff;
    }

    .doctor-reviews {
      text-align: right;
      color     : #fff;
    }
  }

  .ant-form {
    .form-column {
      display: flex;

      &>* {
        flex: 1;

        &:nth-child(even) {
          margin-left: 16px;
        }
      }

      &.booking-for {
        color: $font-green;

        * {
          flex: none;
        }

        .label {
          margin-top: 4px;
        }
      }
    }

    .book-time-section{
      .timezone {
        text-align: center;
        padding: 6px;
        margin-top: 12px;
        color: #aaa;
        user-select: none;
        background: $profile-bg-color;
      }
    }
    .book-for-section.disabled,
    .book-time-section.disabled {
      position: relative;

      &::after {
        content   : '';
        position  : absolute;
        top       : -16px;
        left      : -16px;
        width     : calc(100% + 32px);
        height    : calc(100% + 32px);
        background: rgba($color: #aaa, $alpha: 0.1);
      }
    }

    @media #{$mobile} {
      .form-column {
        display: block;

        &>* {
          flex: 1;

          &:nth-child(even) {
            margin-left: 0;
          }
        }
      }
    }
  }
}