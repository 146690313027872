@import "@/assets/styles/variable.scss";

.account-biz-hr{

  .ant-collapse{
    background: $profile-bg-color;
    .ant-collapse-item{
      margin: 6px 0;
      .ant-collapse-header{
        align-items: center;
        justify-content: space-between;
        .ant-collapse-header-text{
          flex: 1;
        }
        .header-line{
          flex: 1;
          display: flex;
          align-items: center;
          .weekday{
            text-transform: capitalize;
          }
          .ant-switch{
            margin: 0 32px 0 auto;
          }
        }
      }
      .ant-collapse-content-box{
        .form-row{
          display: flex;
          .form-column{
            width: 80%;
            display: flex;
            .ant-form-item{
              flex: 3;
              &:nth-child(2){
                margin-left: 12px;
                flex: 1
              }
            }
            @media #{$mobile} {
              width: 100%;
              display: block;
              .ant-form-item:nth-child(2){
                margin: 6px 0;
              }
            }
          }
          .anticon-close-circle{
            margin-left: 12px;
          }
        }
      }
    }
  }
}