@import '@/assets/styles/variable.scss';

.congratulation-modal {
    .ant-modal-body {
      padding: 64px;
      .congratulation-main {
        .block {
          text-align: center;
          span {
            display: inline-block;
            width: 45px;
            height: 5px;
            background: #E69F6C;
          }
        }
        .congratulation {
          height: 56px;
          line-height: 56px;
          text-align: center;
          margin-top: 32px;
          margin-bottom: 32px;
          font-family: $body-font-merriweather;
          font-size: 48px;
          font-weight: 700;
          color: #46604E;
        }
        .description {
          height: 20px;
          line-height: 20px;
          text-align: center;
          margin-bottom: 48px;
          font-size: 14px;
          color: $font-color;
        }
        .btns {
          text-align: center;
        }
  
      }
      @media #{$mobile} {
        padding: pv(64px 20px);
        .congratulation-main{
          .congratulation{
            height: auto;
            font-size: pv(36px);
            word-wrap: none;
            white-space: nowrap;
          }
        }
      }
    }
  }
  