@import './acu_profile.scss';

.user-profile-form {
  .submit-btn {
    text-align: center;
    margin-top: 76px;
    button {
      width: 196px;
      height: 64px;
      font-size: 16px;
      letter-spacing: 0.5px;
    }
  }
}
