@import "@/assets/styles/variable.scss";

.signin-page {
  // max-width: 558px;
  margin: 116px auto 0;
  .login-form {
    position: relative;
    .forget-password {
      position: relative;
      top: -12px;
      text-align: right;
      span:first-child {
        display: inline-block;
        height: 24px;
        line-height: 24px;
        color: #2F80ED;
        font-size: 14px;
        cursor: pointer;
      }
    }
    .form-item-or{
      font-weight: bold;
      flex: 1;
      position: relative;
      height: 18px;
      line-height: 18px;
      margin: 12px 0;
      font-size: 12px;
      text-align: center;
      color: $font-color-light;
      text-transform: uppercase;
    }
    .google-section{
      margin: 12px 0;
      align-items: center;
    }
    .form-item-link {
      color: $font-color-light;
      text-align: center;
      font-size: 18px;
      a {
        color: #57caca;
        font-weight: 700;
        padding-left: 8px;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    .form-item-btn {
      margin-top: 12px;
      display: flex;
      justify-content: center;
      .ant-btn{
        height: 56px;
      }
    }
    
    .form-item-socialmedia {
      margin-bottom: 40px;
      button {
        position: relative;
        &.facebook {
          color: #FFFFFF;
          margin-bottom: 12px;
          background: #23599C;
          border-color: #23599C;
          &:hover {
            border-color: #23599C;
          }
        }
        &.google {
          color: #FFFFFF;
          background: #2F80ED;
          border-color: #2F80ED;
          &:hover {
            border-color: #2F80ED;
          }
        }
        img {
          position: absolute;
          top: 16px;
          left: 32px;
        }
      }
    }
    
  }
  @media #{$mobile} {
    margin: 0 auto;
    // padding: pv(44px 24px);
    .login-form{
      .forget-password{
        width: 100%;
        text-align: center;
        margin: pv(10px 0 68px);
        font-weight: bold;
      }
    }
  }
}
