.upload-avatar-wrapper {
    text-align: center;
    .ant-upload {
      .upload-avatar {
        cursor: pointer;
        text-align: center;
        .img-wrapper {
          width: 96px;
          height: 96px;
          margin: 0 auto 16px;
          position: relative;
          .avatar {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            border: 1px solid #e1e1e1;
          }
          .delete-avatar {
            width: 24px;
            height: 24px;
            position: absolute;
            right: 0;
            bottom: 0;
            cursor: pointer;
          }
        }
        .text-link {
          height: 20px;
          line-height: 20px;
          margin-top: 16px;
          font-size: 14px;
        }
      }
    }
  }