@import "./variable.scss";
@import "./antdStyle.scss";

html, body, #root, .App, .scale-wrapper {
  height: 100%;
}

html,body {
  color: $font-color;
  font-family: $body-font-roboto;
  ::-webkit-scrollbar{
    width: 8px;
    background-color: #ffffff;
  }
  ::-webkit-scrollbar-track{
    box-shadow: inset 0 0 4px rgba($color: #000000, $alpha: 0.2);
    border-radius: 8px;
    background-color: #ffffff;
  }
  ::-webkit-scrollbar-thumb{
    border-radius: 8px;
    // background-image : -webkit-gradient(linear, left bottom, left top, color-stop(0.2, #fdebc8), color-stop(0.52, #f0eeee), color-stop(0.82, #e0dddd));
    background: #dfdfdf;
    box-shadow: inset 0 0 4px rgba($color: #000000, $alpha: 0.2);

  }
  
}

.common-title {
  font-size: 48px;
  font-family: $body-font-merriweather;
  color: $font-green;
  line-height: 1.15;
  font-weight: bold;
}

.font-family-merriweather {
  font-family: $body-font-merriweather;
}

.font-family-roboto {
  font-family: $body-font-roboto;
}

.text-center {
  text-align: center;
}

.text-link {
  color: $link-blue;
}

//for login/checkemail/resetpwd page
.account-page-block {
  width: 101px;
  height: 7px;
  margin: 0 auto 40px;
  background: $theme-orange;
}

.account-page-title {
  //height: 56px;
  line-height: 56px;
  margin-bottom: 40px;
  font-size: 48px;
  font-weight: 700;
  color: $font-green;
  text-align: center;
}

.account-page-description {
  width: 472px;
  line-height: 20px;
  margin: 0 auto 68px;
  font-size: 14px;
  color: $font-color;
  text-align: center;
  @media #{$mobile} {
    width: 100%;
  }
}

//for form
.common-form-item-title {
  height: 18px;
  line-height: 18px;
  font-size: 12px;
  font-weight: 700;
  color: $font-color-light;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  @media #{$mobile} {
    height: auto;
  }
}

@media #{$mobile} {
  .page-content-wrapper {
    padding-top: pv(64px);
  }
}

.ant-modal.fma-modal {
  min-width: 600px;
  .ant-modal-content{
    .ant-modal-body{
      padding: 32px;
      .ant-modal-confirm-body > .anticon {
        display: none;
      }
      .ant-modal-confirm-title{
        font-size: 32px;
        font-family: $body-font-merriweather;
        font-weight: bold;
        color: $font-green;
        margin-bottom: 16px;
      }
      .ant-modal-confirm-content{
        font-size: 18px;
        margin-left: 0;
      }
      .ant-modal-confirm-btns{
        .ant-btn{
          height: 54px;
        }
      }
    }
  }
  @media #{$mobile} {
    width: 90vw;
    min-width: 80vw;
    .ant-modal-content{
      padding: pv(32px 12px);
      .ant-modal-confirm-content{
        margin: 0;
      }
      .ant-modal-confirm-btns{
        width: 100%;
        display: flex;
        justify-content: space-between;
        .ant-btn{
          width: 45%;
        }
      }
    }
  }
}