@import "@/assets/styles/variable.scss";

.common-search {
  display: flex;
  justify-content: space-between;
  width: 864px;
  height: 128px;
  padding: 32px;
  background: #fff9f2;
  .ant-input-affix-wrapper.ant-input-affix-wrapper-borderless {
    border-bottom: 1px solid rgba(47, 50, 54, 0.2);
    .ant-input-prefix {
      .anticon.anticon-search {
        position: relative;
        top: 1.5px;
      }
    }
    input.ant-input {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
      font-size: 18px !important;
      color: $font-color !important;
      margin-top: 3.5px;
    }
  }
  button.ant-btn {
    color: #fdfeff;
    font-size: 16px;
    font-weight: bold;
    width: 200px;
    height: 64px;
    background: #56a785;
    border: none;
  }
}

@media #{$mobile} {
  .common-search {
    width: 100%;
    height: 92px;
    padding: 24px;
    .ant-input-affix-wrapper.ant-input-affix-wrapper-borderless {
      .ant-input-prefix {
        .anticon.anticon-search {
          left: -6px;
        }
      }
      input.ant-input.ant-input-borderless {
        height: 35px !important;
        padding: 0 !important;
        margin: 0 !important;
        font-size: 14px !important;
        color: $font-color;
      }
    }
    button.ant-btn {
      width: 112px !important;
      height: 44px !important;
    }
  }
}
